import React from "react";
import MOELayout from '../../layouts/MOELayout';

const NQSAwardingPage = () => {

  const dataPage = {
    listAwardingBody: [
      {
        linkEn: 'https://www.actvet.gov.ae/en/Pages/default.aspx',
        linkAr: 'https://www.actvet.gov.ae/ar/Pages/default.aspx',
        titleEn: 'Abu Dhabi Centre for Technical and Vocational Education and Training (ACTVET)',

        titleAr: 'مركز أبوظبي للتعليم و التدريب التقني و المهني - أبوظبي'
      },
      {
        linkEn: 'https://web.khda.gov.ae/en/',
        linkAr: 'https://web.khda.gov.ae/ar/',
        titleEn: 'Knowledge and Human Development Authority (KHDA)', titleAr: 'هيئة المعرفة و التنمية البشرية - دبى'
      },
      {
        linkEn: 'https://moi.gov.ae/en/default.aspx',
        linkAr: 'https://moi.gov.ae/ar/default.aspx',
        titleEn: 'MOI - Security Qualifications Centre', titleAr: 'مركز المؤهلات الأمنية - وزارة الداخلية'
      },
      {
        linkEn: 'https://mod.gov.ae/',
        linkAr: 'https://mod.gov.ae/ar/',
        titleEn: 'GHQ- Military Qualifications Centre', titleAr: 'مركز المؤهلات العسكري – وزارة الدفاع'
      },
      {
        linkEn: 'https://www.moj.gov.ae/en/home.aspx',
        linkAr: 'https://www.moj.gov.ae/ar/home.aspx',
        titleEn: 'Judicial Training Institute', titleAr: 'معهد التدريب القضائي'
      },
      {
        linkEn: 'https://nihs.uaeu.ac.ae/en/index.shtml',
        linkAr: 'https://nihs.uaeu.ac.ae/ar/',
        titleEn: 'National Institute of Health', titleAr: 'المعهد الوطني للتخصصات الصحية'
      },
      {
        linkEn: 'https://www.gcaa.gov.ae/en/Pages/home.aspx',
        linkAr: 'https://www.gcaa.gov.ae/ar/Pages/home.aspx',
        titleEn: 'General Civil Aviation Authority', titleAr: 'الهيئة العامة للطيران المدني'
      },
      {
        linkEn: 'https://spea.shj.ae/en/',
        linkAr: 'https://spea.shj.ae/',
        titleEn: 'Sharjah Private Education Authority', titleAr: 'هيئة الشارقة للتعليم الخاص'
      }
    ],
    descriptonAr: 'هي جهة معتمدة من المركز الوطني للقيام بالرقابة على المؤسسات التدريبية المعتمدة و المسجلة و التأكد من ضمان جودة طرح المؤهلات المهنية و اصدار الشهادات المهنية وفق الضوابط في المركز الوطني للمؤهلات .',
    descriptonEn: 'An Awarding Body is an organization accredited by the NQC to monitor Accredited Training Provider (ATP) performance, ensure the quality of qualifications delivery, and issue vocational qualification certificates as per NQC policies.'
    , headEn: 'Accredited Awarding Bodies:',
    headAr: 'الجهات المانحة المعتمدة:'
  }


  return (
    <MOELayout>
      <div className="flex flex-col">
        <p className="lg:w-3/4 w-full !mb-[48px] lg:!mb-24 text-xl !leading-[30px]">
          {isArabic ? dataPage.descriptonAr : dataPage.descriptonEn} </p>
        <h5 className="text-h5 font-bold mb-3"> {isArabic ? dataPage.headAr : dataPage.headEn}</h5>
        <ul style={{listStyle: 'auto'}}>
          {dataPage.listAwardingBody.map((item, index) => (
            <li
              key={index}
              onClick={() => window.open(isArabic ? item.linkAr : item.linkEn, '_blank')}
              className="rounded-none leading-8 cursor-pointer"
              ref={(el) => el && el.style.setProperty('text-decoration', 'underline', 'important')}
            >
              {isArabic ? item.titleAr : item.titleEn}
            </li>


          ))
          }
        </ul>


      </div>
    </MOELayout >
  );
};

export default NQSAwardingPage;
