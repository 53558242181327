/* eslint-disable import/no-unused-modules */
import {fetchLegislation} from "../../api/legislation.api";

export const SET_LAWS = "SET_LAWS";
export const SET_LAW_CATEGORIES = "SET_LAW_CATEGORIES";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const SET_SELECTED_YEAR = "SET_SELECTED_YEAR";
export const SET_SEARCH = "SET_SEARCH";
export const SET_LOADING = "SET_LOADING ";
export const SET_ERROR = "SET_ERROR";

// New action types
export const FILTER_LAWS = "FILTER_LAWS";
export const RESET_FILTERS = "RESET_FILTERS";
export const APPLY_FILTERS = "APPLY_FILTERS";
export const UPDATE_SEARCH = "UPDATE_SEARCH";

export const fetchLaws = () => async (dispatch) => {
    dispatch({type: SET_LOADING, payload: true});
    try {
        // Fetch the list of laws and categories
        const {list, categories} = await fetchLegislation();
        // Order the list by release year and filter the list by isPublished
        const orderedList = list.length > 0 ? list
            .sort((a, b) => b.releaseYear - a.releaseYear)
            .filter(
                (law) =>
                    law[isArabic ? "isPublishedAr" : "isPublished"] === true,
            ) : [];
        console.log("orderedList", orderedList);
        const lawListByCategory = {
            laws: orderedList,
            filteredLaws: orderedList,
            // Filter the list by category if LegislationCategoryId is contains 1
            MinisterialDecrees: orderedList.filter(
                (law) => law.LegislationCategoryId.includes(3), // 3 is Ministerial Decrees
            ),
            FederalLaws: orderedList.filter(
                (law) => law.LegislationCategoryId.includes(1), // 1 is Federal Laws
            ),
            InstitutionalPolicies: orderedList.filter(
                (law) => law.LegislationCategoryId.includes(2), // 2 is Institutional Policies
            ),
            FederalDecrees: orderedList.filter(
                (law) => law.LegislationCategoryId.includes(4), // 4 is Federal Decrees
            ),
        };
        // Set the laws and categories to the redux store
        dispatch({type: SET_LAWS, payload: lawListByCategory});
        dispatch({type: SET_LAW_CATEGORIES, payload: categories});
        dispatch({type: SET_ERROR, payload: null});
    } catch (err) {
        dispatch({type: SET_ERROR, payload: err});
    } finally {
        dispatch({type: SET_LOADING, payload: false});
    }
};

// Action creators
export const resetFilters = () => (dispatch) => {
    // 1- Reset the filters to the initial state
    dispatch({type: RESET_FILTERS});
};

// Filter laws helper function
const filterLaws = (laws, filters) => laws.filter((law) => {
    // 1- Check if the category matches the selected category
    const categoryMatch =
        !filters.selectedCategory ||
        law.LegislationCategoryId.includes(+filters.selectedCategory);
    // 2- Check if the year matches the selected year
    const yearMatch =
        !filters.selectedYear ||
        law.releaseYear === filters.selectedYear;
    // 3- Check if the search value matches the title
    const searchMatch =
        !filters.search ||
        law[isArabic ? "TitleAr" : "Title"]
            .toLowerCase()
            .includes(filters.search.toLowerCase());
    // 4- Return true if all the matches are true
    return categoryMatch && yearMatch && searchMatch;
});

export const applyFilters = () => (dispatch, getState) => {
    // 1- Get the laws and filters from the redux store
    const {laws, selectedCategory, selectedYear, search} = getState().legislation;
    const filters = {selectedCategory, selectedYear, search};
    // 2- Filter the laws based on the filters
    const filteredLawsList = filterLaws(laws, filters);
    // From filteredLawsList, filter the laws by category and return the filtered laws
    const filteredMinisterialDecrees = filteredLawsList.filter(law => law.LegislationCategoryId.includes(1));
    const filteredFederalLaws = filteredLawsList.filter(law => law.LegislationCategoryId.includes(2));
    const filteredInstitutionalPolicies = filteredLawsList.filter(law => law.LegislationCategoryId.includes(3));
    const filteredFederalDecrees = filteredLawsList.filter(law => law.LegislationCategoryId.includes(4));
    // 3- Dispatch the filtered laws to the redux store
    dispatch({
        type: APPLY_FILTERS,
        payload: {
            filteredLaws: filteredLawsList,
            MinisterialDecrees: filteredMinisterialDecrees,
            FederalLaws: filteredFederalLaws,
            InstitutionalPolicies: filteredInstitutionalPolicies,
            FederalDecrees: filteredFederalDecrees,
        }
    });
};

export const updateSearch = (laws, searchValue) => (dispatch) => {
    // 1- Filter the laws based on the search value
    const filteredLaws = laws.filter((law) =>
        law[isArabic ? "TitleAr" : "Title"]
            .toLowerCase()
            .includes(searchValue.toLowerCase())
    );
    // 2- Dispatch the filtered laws to the redux store
    dispatch({
        type: UPDATE_SEARCH,
        payload: {
            filteredLaws,
            searchValue
        }
    });
};

