import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { siteURL } from "@constants/constants";
import MOELayout from "../../layouts/MOELayout";
import MOEPagenation from "@components/MOEPagenation";
import { CatLegislationsList, years } from "./data";
import pdfImage from "@images/pdfImage.png";

import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBCol,
} from "mdb-react-ui-kit";
import { getServicesPolicies } from "../../api/nqc.api";

const LegislationsPage = () => {
    const [openData, setOpenData] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [fullscreenModal, setFullscreenModal] = useState(false);
    const [perPage, setPerPage] = useState(12);
    const [isOpen, setIsOpen] = useState(false);
    const [allData, setAllData] = useState(null);
    const [searchPhrase, setSearchPhrase] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const debounceTimeout = useRef(null);
    const [loading, setLoading] = useState(true);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };
    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getServicesPolicies();

                const data = response.data.value;
                setAllData(response.data.value);
                setOpenData({
                    totalFiles: data.length,
                    data: data,
                });
                setLoading(false);
                // const retrievedArray = getCookie("myHappiness");
                // setMyHappiness(retrievedArray);
                // getDatasetFeedback();
            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const changePage = (textContent) => {
        setCurrentPage(textContent);
        $("#s4-workspace").animate({ scrollTop: 0 }, "slow");
    };
    const dropdownRef = useRef(null);
    const handleDocumentClick = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    if (typeof window !== "undefined") {
        document.onclick = handleDocumentClick;
    }
    const handleDownload = async (itemPath) => {
        const response = await fetch(
            `${siteURL}${isArabic ? "/AR" : "/EN"}/NQC/PoliciesProcedures/${itemPath}`,
        );
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", itemPath);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
    };

    const filterData = (value) => {
        let filteredData = allData;

        if (selectedCategory) {
            filteredData = filteredData.filter(
                (item) => item.DocType === selectedCategory,
            );
        }
        if (selectedYear) {
            filteredData = filteredData.filter((item) => {
                return new Date(item.DocDate).getFullYear() == selectedYear;
            });
        }

        if (value) {
            filteredData = filteredData.filter((item) =>
                item.LinkFilename.slice(0, -4)
                    ?.toLowerCase()
                    .includes(value.toLowerCase()),
            );
        }

        setOpenData({
            totalFiles: filteredData.length,
            data: filteredData,
        });
    };
    const handleApply = () => {
        setFullscreenModal(false);
        setIsOpen(false);
        filterData(searchPhrase);
        changePage(1);
    };
    const handleReset = () => {
        setSelectedYear("");
        setSelectedCategory("");
        setSearchPhrase("");
        setSearchValue("");
        setIsOpen(false);
        setFullscreenModal(false);
        setOpenData({
            totalFiles: allData.length,
            data: allData,
        });
        changePage(1);
    };
    const toggleOpen = () => setFullscreenModal(!fullscreenModal);

    // const handleHappiness = (happiness, item) => {
    //   const includesId = myHappiness.some((element) => element.id === item.Id);
    //   if (myHappiness.length == 0 || (myHappiness.length > 0 && !includesId)) {
    //     let array = myHappiness;
    //     array.push({
    //       id: item.Id,
    //       type: happiness,
    //     });
    //     setCookie("myHappiness", array);
    //     updateSharePointList(item.Id, "Happiness", happiness);
    //   }
    // };
    // const setCookie = (cookieName, array) => {
    //   const arrayString = JSON.stringify(array);
    //   document.cookie = `${cookieName}=${arrayString}; expires=Thu, 01 Jan 2099 00:00:00 UTC; path=/`;
    // };

    // const getCookie = (cookieName) => {
    //   const cookies = document.cookie;
    //   const cookieArray = cookies.split("; ");
    //   for (let i = 0; i < cookieArray.length; i++) {
    //     const cookie = cookieArray[i].split("=");
    //     if (cookie[0] === cookieName) {
    //       return JSON.parse(cookie[1]);
    //     }
    //   }

    //   return [];
    // };
    // const getRatedCount = (itemId, type) => {
    //   let res = datasetsFeedback.find((item) => item.DatasetIdId == itemId);
    //   if (res) return res[type];
    //   return 0;
    // };
    // const getDatasetFeedback = async () => {
    //   const listEndpoint = `${siteURL}_api/web/lists/getbytitle('DatasetFeedback')/items`;
    //   let settings = {
    //     async: false,
    //     crossDomain: true,
    //     url: listEndpoint,
    //     method: "GET",
    //     headers: {
    //       accept: "application/json; odata=verbose",
    //       "cache-control": "no-cache",
    //     },
    //   };
    //   await $.ajax(settings).done((response) => {
    //     let data = response.d.results;

    //     setDatasetsFeedback(data);
    //   });
    // };
    // const updateSharePointList = async (itemId, eventType, value) => {
    //   const listEndpoint = `${siteURL}/_api/web/lists/getbytitle('DatasetFeedback')/items`;
    //   const checkItemUrl = `${listEndpoint}?$filter=DatasetIdId eq '${itemId}'`;

    //   try {
    //     const data = await $.ajax({
    //       url: checkItemUrl,
    //       type: "GET",
    //       headers: {
    //         Accept: "application/json;odata=verbose",
    //       },
    //     });

    //     const itemExists = data.d.results.length > 0;

    //     {
    //       const itemPayload = {
    //         __metadata: {
    //           type: "SP.Data.PoliciesProceduresItem",
    //         },
    //         DatasetIdId: itemId,
    //         DownloadsCount: eventType === "DownloadsCount" ? 1 : undefined,
    //         like: eventType === "Happiness" && value == "like" ? 1 : undefined,
    //         dislike:
    //           eventType === "Happiness" && value == "dislike" ? 1 : undefined,
    //       };

    //       await $.ajax({
    //         url: `${siteURL}${listEndpoint}`,
    //         type: "POST",
    //         data: JSON.stringify(itemPayload),
    //         contentType: "application/json;odata=verbose",
    //         headers: {
    //           Accept: "application/json;odata=verbose",
    //           "Content-Type": "application/json;odata=verbose",
    //           "X-RequestDigest": $("#__REQUESTDIGEST").val(),
    //         },
    //       });
    //     }
    //     const retrievedArray = getCookie("myHappiness");
    //     // setMyHappiness(retrievedArray);
    //     // getDatasetFeedback();

    //     if (eventType === "DownloadsCount") {
    //       let link = document.createElement("a");
    //       link.href = value;
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //     }
    //   } catch (error) {""
    //     console.log(`Error: ${error}`);
    //   }
    // };
    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            setSearchPhrase(value);

            setCurrentPage(1);
            filterData(value);
        }, 1000);
    };

    return (
        <MOELayout>
            {loading ? (
                <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-2">
                    {Array(perPage)
                        .fill()
                        .map((item, index) => (
                            <Skeleton
                                height={200}
                                style={{ borderRadius: ".5rem" }}
                                key={index}
                            />
                        ))}
                </div>
            ) : (
                <div className="open-data flex flex-col  gap-8 lg:gap-12 pb-12">
                    <div className="flex gap-2 justify-between">
                        <button
                            className=" basis-1/4 bg-transparent p-0 gap-2 flex lg:hidden"
                            onClick={toggleOpen}
                            type="button"
                        >
                            <span>{isArabic ? "بحث" : "Filter"} </span>
                            <i class="ph ph-sliders"></i>
                        </button>

                        <div className="relative" ref={dropdownRef}>
                            <button
                                className="aegov-btn aegov-btn-filter w-max hidden lg:flex"
                                type="button"
                                onClick={toggleDropdown}
                            >
                                <span>{isArabic ? "بحث" : "Filter"} </span>
                                <i className="ph ph-sliders"></i>
                            </button>

                            {isOpen && (
                                <div
                                    className={`aegov-dropdown absolute rounded-2xl ${isArabic ? "right-0" : "left-0"}  ${
                                        isOpen ? "block" : "hidden"
                                    }`}
                                >
                                    <div className="lg:w-80 p-6 border border-primary-400 rounded-2xl space-y-8 bg-whitely-50">
                                        <section className="space-y-4">
                                            <h6>
                                                {isArabic ? "بحث" : "Filter"}{" "}
                                            </h6>
                                            <div className="space-y-3 max-h-[32rem] overflow-auto">
                                                <div className="flex flex-col gap-4">
                                                    <div className="aegov-form-control">
                                                        <div className="form-control-input">
                                                            <select
                                                                value={
                                                                    selectedCategory ||
                                                                    ""
                                                                }
                                                                onChange={
                                                                    handleCategoryChange
                                                                }
                                                            >
                                                                <option
                                                                    className="dark:!bg-gray-800"
                                                                    value=""
                                                                >
                                                                    {isArabic
                                                                        ? "اختر التصنيف"
                                                                        : "Select Category"}
                                                                </option>
                                                                {CatLegislationsList.map(
                                                                    (
                                                                        item,
                                                                        index,
                                                                    ) => (
                                                                        <option
                                                                            className="dark:!bg-gray-800"
                                                                            value={
                                                                                isArabic
                                                                                    ? item.categoryAr
                                                                                    : item.categoryEn
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {isArabic
                                                                                ? item.categoryAr
                                                                                : item.categoryEn}
                                                                        </option>
                                                                    ),
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="aegov-form-control">
                                                        <div className="form-control-input">
                                                            <select
                                                                value={
                                                                    selectedYear ||
                                                                    ""
                                                                }
                                                                onChange={
                                                                    handleYearChange
                                                                }
                                                            >
                                                                <option
                                                                    className="dark:!bg-gray-800"
                                                                    value=""
                                                                >
                                                                    {isArabic
                                                                        ? "اختر السنة"
                                                                        : "Select Year"}
                                                                </option>
                                                                {years.map(
                                                                    (
                                                                        item,
                                                                        index,
                                                                    ) => (
                                                                        <option
                                                                            className="dark:!bg-gray-800"
                                                                            value={
                                                                                item.year
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item.year
                                                                            }
                                                                        </option>
                                                                    ),
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between gap-2">
                                                        <button
                                                            onClick={
                                                                handleReset
                                                            }
                                                            className="aegov-btn btn-outline  !text-aeblack-800 !border-aeblack-800 btn-sm flex-1"
                                                            type="button"
                                                        >
                                                            <i className="ph ph-arrow-counter-clockwise"></i>
                                                            {isArabic
                                                                ? "إعادة"
                                                                : "Reset"}
                                                        </button>
                                                        <button
                                                            onClick={
                                                                handleApply
                                                            }
                                                            className="aegov-btn btn-sm flex-1"
                                                            type="button"
                                                            disabled={
                                                                !selectedCategory &&
                                                                !selectedYear
                                                            }
                                                        >
                                                            {isArabic
                                                                ? "تطبيق"
                                                                : "Apply"}

                                                            <i className="ph ph-check"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className=" aegov-form-control">
                            <div className="form-control-input max-w-[300px] ">
                                <input
                                    type="search"
                                    name="search_suffix"
                                    id="search_suffix"
                                    placeholder={
                                        isArabic
                                            ? "تشريعات و أدلة"
                                            : "Legislations and Guides"
                                    }
                                    value={searchValue}
                                    onChange={handleSearchChange}
                                />
                                <span className="control-suffix">
                                    <i className="ph ph-magnifying-glass text-xl"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="open-data flex flex-col gap-4 pb-12">
                        {openData.data.length > 0 ? (
                            <div className=" grid xl:grid-cols-4 lg:grid-cols-3   sm:grid-cols-2 gap-[16px] lg:gap-6">
                                {openData.data
                                    .slice(
                                        (currentPage - 1) * perPage,
                                        currentPage * perPage,
                                    )
                                    .map((item, index) => (
                                        <div
                                            className="aegov-card card-bordered border-none   px-4 lg:px-2 flex flex-col"
                                            key={index}
                                        >
                                            <div className="d-flex justify-content-between items-baseline">
                                                <div className="card-content flex gap-1 items-baseline">
                                                    <span
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title={
                                                            isArabic
                                                                ? "تاريخ"
                                                                : "Date"
                                                        }
                                                    >
                                                        <span className="text-aeblack-500 text-xs">
                                                            {" "}
                                                            {isArabic
                                                                ? "تاريخ"
                                                                : "Date"}
                                                        </span>
                                                    </span>
                                                    <span className="text-xs">
                                                        {moment(item.DocDate)
                                                            .locale(
                                                                isArabic
                                                                    ? "ar"
                                                                    : "en",
                                                            )
                                                            .format(
                                                                isArabic
                                                                    ? "MMMM DD, YYYY"
                                                                    : "DD MMM YYYY",
                                                            )
                                                            .replace(
                                                                /[٠-٩]/g,
                                                                (d) =>
                                                                    "0123456789"[
                                                                        "٠١٢٣٤٥٦٧٨٩".indexOf(
                                                                            d,
                                                                        )
                                                                    ],
                                                            )}
                                                    </span>
                                                </div>

                                                {item.DocType && (
                                                    <span
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        className="flex items-center"
                                                        title={item.DocType}
                                                    >
                                                        <i class="ph ph-book text-primary-500 text-lg"></i>
                                                        <span className="text-aeblack-500 text-xs leading-[18px]">
                                                            {item.DocType}
                                                        </span>
                                                    </span>
                                                )}
                                            </div>
                                            <h3 className="text-xl font-semibold  col my-3">
                                                {item.LinkFilename.slice(0, -4)}
                                            </h3>
                                            <div className="card-footer p-0">
                                                <div className="mt-4 flex justify-content-end items-baseline">
                                                    <div className="flex items-normal justify-content-end gap-[16px]">
                                                        <div className="flex flex-col gap-1">
                                                            <span className="text-aeblack-500 text-xs">
                                                                {isArabic
                                                                    ? "تنزيل"
                                                                    : "Download"}
                                                            </span>
                                                            <span className="text-xs ltr:text-end rtltext-start">
                                                                {Math.floor(
                                                                    item?.FileSizeDisplay /
                                                                        1000,
                                                                )}
                                                                Kb
                                                            </span>
                                                        </div>

                                                        <div>
                                                            {
                                                                <a
                                                                    className="cursor-pointer"
                                                                    onClick={() =>
                                                                        handleDownload(
                                                                            item.LinkFilename,
                                                                        )
                                                                    }
                                                                >
                                                                    <span
                                                                        data-toggle="tooltip"
                                                                        width="43"
                                                                        aria-label="12kb"
                                                                        data-mdb-original-title="12kb"
                                                                    />
                                                                    <img
                                                                        className="img"
                                                                        src={
                                                                            pdfImage
                                                                        }
                                                                        alt="pdf"
                                                                    />
                                                                </a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        ) : (
                            <div className="font-bold text-2xl text-center">
                                {isArabic ? "لاتوجد بيانات" : "No Data Found"}
                            </div>
                        )}
                        <MOEPagenation
                            perPage={perPage}
                            total={openData.totalFiles}
                            handlePageClick={(e) =>
                                changePage(parseInt(e.target.textContent))
                            }
                            currentPage={currentPage}
                        />
                    </div>
                </div>
            )}

            <MDBModal
                show={fullscreenModal}
                onHide={() => setFullscreenModal(false)}
                className="assessment-Modal"
            >
                <MDBModalDialog size="fullscreen">
                    <MDBModalContent>
                        <div className="flex text-aeblack-900  text-lg mb-3">
                            <i
                                className={`ph ${isArabic ? "ph-caret-right" : "ph-caret-left"}`}
                                onClick={toggleOpen}
                            ></i>
                            <p className="mx-auto text-sm">
                                {isArabic
                                    ? "تصفية حسب الفئات"
                                    : "Filter by categories"}
                            </p>
                        </div>

                        <MDBModalBody>
                            <h4>{isArabic ? "التصنيف" : "Category"}</h4>
                            <div className="flex gap-1 flex-wrap">
                                <span
                                    className={`border border-aeblack-500 w-fit p-2 text-sm rounded-3xl cursor-pointer hover:bg-aeblack-500  ${!selectedCategory ? "bg-aeblack-500 text-white" : ""}`}
                                    onClick={() =>
                                        handleCategoryChange({
                                            target: { value: "" },
                                        })
                                    }
                                >
                                    {isArabic ? "الكل" : "All"}
                                </span>

                                {CatLegislationsList.map((item, index) => (
                                    <span
                                        key={index}
                                        className={`border border-aeblack-500 w-fit p-2 text-sm rounded-3xl cursor-pointer hover:bg-aeblack-500  ${selectedCategory == (isArabic ? item.categoryAr : item.categoryEn) ? "bg-aeblack-500 text-white" : ""}`}
                                        onClick={() =>
                                            handleCategoryChange({
                                                target: {
                                                    value: isArabic
                                                        ? item.categoryAr
                                                        : item.categoryEn,
                                                },
                                            })
                                        }
                                    >
                                        {isArabic
                                            ? item.categoryAr
                                            : item.categoryEn}
                                    </span>
                                ))}
                            </div>
                            <hr className="my-4 text-aeblack-100" />
                            <h4>{isArabic ? "السنة" : "Year"}</h4>
                            <div className="flex gap-1 flex-wrap">
                                <span
                                    className={`border border-aeblack-500 w-fit p-2 text-sm rounded-3xl cursor-pointer hover:bg-aeblack-500  ${!selectedYear ? "bg-aeblack-500 text-white" : ""}`}
                                    onClick={() =>
                                        handleYearChange({
                                            target: { value: "" },
                                        })
                                    }
                                >
                                    {isArabic ? "الكل" : "All"}
                                </span>

                                {years.map((item, index) => (
                                    <span
                                        key={index}
                                        className={`border border-aeblack-500 w-fit p-2 text-sm rounded-3xl cursor-pointer hover:bg-aeblack-500  ${selectedYear === item.year ? "bg-aeblack-500 text-white" : ""}`}
                                        onClick={() =>
                                            handleYearChange({
                                                target: {
                                                    value: item.year,
                                                },
                                            })
                                        }
                                    >
                                        {item.year}
                                    </span>
                                ))}
                            </div>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <div className="flex flex-col gap-2 w-full">
                                <button
                                    onClick={() => {
                                        handleApply();
                                        setFullscreenModal(false);
                                    }}
                                    className="aegov-btn btn-lg"
                                    type="button"
                                >
                                    {isArabic ? "عرض النتائج" : "See Results"}
                                </button>
                                <button
                                    onClick={handleReset}
                                    className="aegov-btn btn-outline  btn-lg"
                                    type="button"
                                >
                                    {isArabic ? "إعادة" : "Reset"}
                                </button>
                            </div>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </MOELayout>
    );
};

export default LegislationsPage;
