/* eslint-disable import/no-unused-modules */
import axios from "axios";
import {siteURL} from "../constants/constants"
import {getURLFileSize} from "../helper/getURLFileSize";

/**
 * Fetches the list of laws with their attachment files and sizes
 * @returns {Promise<Array>} Array of processed laws with file sizes
 */
export const fetchLawsList = async () => {
    try {
        const response = await axios.get(
            `${siteURL}/_api/web/lists/getbytitle('LegislationList')/items?$expand=AttachmentFiles`,
        );

        // Process each law to include file size
        const processedLaws = await Promise.all(response.data.value.map(async (law) => {
            if (law[isArabic ? 'FileLinkAr' : 'FileLink']) {
                try {
                    let URLLinkDecoded = decodeURI(isArabic ? law.FileLinkAr : law.FileLink);
                    let fileSize = await getURLFileSize(URLLinkDecoded);
                    fileSize /= (1024 * 1024);
                    return {...law, size: fileSize.toFixed(2)};
                } catch (error) {
                    console.error("Error getting file size:", error);
                    return law;
                }
            }
            return law;
        }));

        return processedLaws;
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

/**
 * Fetches all law categories from the Legislation list
 * @returns {Promise<Array>} Array of law categories
 */
export const fetchLawCategories = async () => {
    try {
        const response = await axios.get(
            `${siteURL}/_api/web/lists/getbytitle('Legislation')/items`,
        );
        return response.data.value;
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

/**
 * Fetches both legislation list and categories simultaneously
 * @returns {Promise<{list: Array, categories: Array}>} Object containing both laws list and categories
 */
export const fetchLegislation = async () => {
    const [list, categories] = await Promise.all([
        fetchLawsList(),
        fetchLawCategories(),
    ]);
    return {list, categories};
};

/**
 * Fetches laws data for a specific category and updates state
 * @param {string} categoryId - The ID of the category to fetch laws for
 * @param {Function} setStateFunction - State setter function to update the component
 * @param {string} titleEn - English title for the category
 * @param {string} titleAr - Arabic title for the category
 * @returns {Promise<void>}
 */
export const fetchLawsDataByCategory = async (
    categoryId
) => {
    try {
        const response = await axios.get(
            `${siteURL}/_api/web/lists/getbytitle('LegislationList')/items?$expand=AttachmentFiles&$filter=LegislationCategoryId eq '${categoryId}'`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        // Process each law to include file size, similar to fetchLawsList
        // Process each law to include file size
        const processedLaws = await Promise.all(response.data.value.map(async (law) => {
            if (law[isArabic ? 'FileLinkAr' : 'FileLink']) {
                let URLLinkDecoded = decodeURI(isArabic ? law.FileLinkAr : law.FileLink);
                try {
                    let fileSize = await getURLFileSize(URLLinkDecoded);
                    fileSize /= (1024 * 1024);
                    return {...law, size: fileSize.toFixed(2)};
                } catch (error) {
                    console.error("Error getting file size:", error);
                    return law;
                }
            }
            return law;
        }));

        return processedLaws;
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
}