/* eslint-disable node/no-missing-import */
import React, { Suspense } from "react";
import { Bounce, ToastContainer } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import InnerPages from "./pages/InnerPages";
import {
    PhotoGalleryPage,
    GovernmentCharterPage,
    VideoGalleryPage,
    NewsPage,
    Initiatives,
    FacilitiesDirectory,
    SchoolsMapPage,
    EServicesPage,
    HomePage,
    ServiceCardPage,
    ContactUsPage,
    AcademicCalendar,
    SurveyResults,
    SurveyPage,
    OpenDataPage,
    DataVisualizationPage,
    InternalAuditPage,
    FAQsPage,
    ImportantLinksPage,
    EventsPage,
    SearchResultPage,
    AlumniPartners,
    GlobalAlumni,
    NQSPage,
    SharikConsultations,
    SharikBlogs,
    Legislation,
} from "./pages";
import UniversityPartnershipsPage from "./pages/UniversityPartnerships";
import UniversityPartnershipsDetails from "./pages/UniversityPartnershipsDetails";
import MinisterialDecrees from "./pages/Legislation/MinisterialDecrees";
import CabinetResolutions from "./pages/Legislation/CabinetResolutions";
import Law from "./pages/Legislation/Law";
import NQCPartnerPage from "./pages/NQCPartner";
import TrainingCentersPage from "./pages/TrainingCentersPage";
import EducationalConsultingPage from "./pages/EducationalConsultingPage";

// Utility to dynamically load components with Suspense fallback
const loadPageComponent = (Component, fallbackClass = "content-skeleton") => {
    if (!Component) {
        console.error("Component is undefined. Check imports and map keys.");
        return (
            <div className="text-center">
                {isArabic ? "الصفحة غير متوفرة" : "Page not available"}
            </div>
        );
    }
    return (
        <Suspense fallback={<Skeleton className={fallbackClass} />}>
            {Component}
        </Suspense>
    );
};

// Map for page content components
const pageComponentsMap = {
    "home-page": <HomePage />,
    "EServices-page": <EServicesPage />,
    "NQS-page": <NQSPage />,
    "NQC-partner-page": <NQCPartnerPage />,
    "Educational-consulting-page": <EducationalConsultingPage />,
    "Training-centers-page": <TrainingCentersPage />,
    "contact-us": <ContactUsPage />,
    "service-card-page": <ServiceCardPage />,
    "serviceCard-page": <ServiceCardPage />,
    news: <NewsPage />,
    schoolsmap: <SchoolsMapPage />,
    "government-charter": <GovernmentCharterPage />,
    "academic-calendar": <AcademicCalendar />,
    "survay-results": <SurveyResults />,
    "search-results": <SearchResultPage />,
    legislation: <Legislation />,
    faq: <FAQsPage />,
    "alumni-partners": <AlumniPartners />,
    "global-alumni": <GlobalAlumni />,
    "important-links": <ImportantLinksPage />,
    "survey-page": <SurveyPage />,
    "sharik-consultations": <SharikConsultations />,
    "sharik-blogs": <SharikBlogs />,
    404: null,
};

// Extract page attributes from DOM
const pageContentAttr = document
    .getElementById("moe-content")
    ?.getAttribute("data-content");
// const pageSubContentAttr = $("#moe-sub-content").attr("data-sub-content");
const pageSubContentAttr = document
    .getElementById("moe-sub-content")
    ?.getAttribute("data-sub-content");

// Function to handle inner pages with additional options
const renderInnerPage = (subContentAttr, content, options) => {
    const innerPagesMap = {
        "ministerial-decrees": <MinisterialDecrees />,
        "cabinet-resolutions": <CabinetResolutions />,
        law: <Law />,
        "sharik-blogs": <SharikBlogs />,
        "sharik-consultations": <SharikConsultations />,
        "photo-gallery": <PhotoGalleryPage />,
        "video-gallery": <VideoGalleryPage />,
        "news-page": <NewsPage />,
        "events-page": <EventsPage />,
        "survay-results": <SurveyResults />,
        openData: <OpenDataPage />,
        dataVisualization: <DataVisualizationPage />,
        "internal-audit-page": <InternalAuditPage />,
        "initiatives-page": <Initiatives />,
        "facilities-directory": <FacilitiesDirectory />,
        "university-partnerships": <UniversityPartnershipsPage />,
        "university-partnerships-details": <UniversityPartnershipsDetails />,
    };

    const InnerPageContent = innerPagesMap[subContentAttr];
    return InnerPageContent ? (
        <Suspense>
            <InnerPages options={options} Content={InnerPageContent}>
                {content}
            </InnerPages>
        </Suspense>
    ) : null;
};

// Main function to render the appropriate component
const GetPageContent = () => {
    // Check if page content matches predefined components
    if (pageComponentsMap[pageContentAttr]) {
        // Load the component with fallback skeleton

        return loadPageComponent(pageComponentsMap[pageContentAttr]);
    }

    // Handle "inner-pages" case with sub-content attributes
    if (pageContentAttr === "inner-pages") {
        const pageContent = $("#moe-content").html();
        return renderInnerPage(pageSubContentAttr, pageContent);
    }

    // Fallback for unsupported pages
    return (
        <div className="text-center">
            {isArabic ? "الصفحة غير متوفرة" : "Page not available"}
        </div>
    );
};

// Render the main content
const App = () => (
    <>
        <GetPageContent />
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={isArabic}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Bounce}
        />
    </>
);
export default App;
