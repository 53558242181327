import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBCol,
} from "mdb-react-ui-kit";
import React, { useEffect, useState, useRef } from "react";
import MOELayout from "../../layouts/MOELayout";
import MOEPagenation from "@components/MOEPagenation";
import { emiratesCBMC, LevelsCBMC, sectors, statuses } from "./data";
import api from "../../api";
import { getServicesCBMC } from "../../api/nqc.api";
const CBMCPage = () => {
    const getClassName = (val) => {
        switch (val) {
            case "Active":
            case "نشط":
                return "bg-aegreen-700";
            default:
                return "bg-aered-700";
        }
    };
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const [openData, setOpenData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [qualificationData, setQualificationData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [fullscreenModal, setFullscreenModal] = useState(false);
    const [searchPhrase, setSearchPhrase] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedSector, setSelectedSector] = useState(null);
    const [selectedEmirate, setSelectedEmirate] = useState(null);
    const [selectedQualificationLevel, setSelectedQualificationLevel] =
        useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [allData, setAllData] = useState(null);
    const [loading, setLoading] = React.useState(true);
    const [searchValue, setSearchValue] = useState("");
    const debounceTimeout = useRef(null);
    const dropdownRef = useRef(null);
    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            setSearchPhrase(value);
            setCurrentPage(1);
            filterData(value);
        }, 1000);
    };
    const handleSectorChange = (event) => {
        setSelectedSector(event.target.value);
    };

    const handleQualificationLevelChange = (event) => {
        setSelectedQualificationLevel(event.target.value);
    };

    const handleEmirateChange = (event) => {
        setSelectedEmirate(event.target.value);
    };

    const covertXML = (xml) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, "text/xml");
        const cbmcDetails = xmlDoc.getElementsByTagName("CBMCDetails");

        const results = [];
        for (let i = 0; i < cbmcDetails.length; i++) {
            const item = cbmcDetails[i];
            const qualificationName =
                item.getElementsByTagName("qualificationName")[0].textContent;
            const totalCreditHour =
                item.getElementsByTagName("totalCreditHour")[0].textContent;
            const qualificationLevel = item.getElementsByTagName(
                "qualificationLevelName",
            )[0].textContent;
            const sector = item.getElementsByTagName("sector")[0].textContent;
            const sectorName =
                item.getElementsByTagName("sectorName")[0].textContent;

            results.push({
                qualificationName,
                totalCreditHour,
                qualificationLevel,
                sector,
                sectorName,
            });
        }
        return results;
    };
    const extractXmlData = (xml) => {
        const result = covertXML(xml);
        setQualificationData(result);

        setShowModal(true);
    };

    const handleViewClick = async (item) => {
        try {
            const response = await api.get(
                `/Integration/nqcwebsite/rtps/${item.id}`,
                {
                    params: {
                        searchPhrase: "",
                        emirateId: null,
                        pageIndex: 0,
                        pageSize: 100,
                    },
                },
            );
        } catch (error) {
            console.error("Error fetching qualification data:", error);
        }
    };
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleDocumentClick = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };
    if (typeof window !== "undefined") {
        document.onclick = handleDocumentClick;
    }

    useEffect(() => {
        const fetchApprenticeshipData = async () => {
            try {
                const response = await getServicesCBMC();
                const allData = response.data.value;

                const processedData = allData.map((item) => {
                    const result = covertXML(item.CBMCDetails);
                    return {
                        ...item,
                        processedDetails: result,
                    };
                });

                setAllData(processedData);
                setOpenData({
                    totalFiles: allData.length,
                    data: processedData,
                });
                setLoading(false);
            } catch (error) {
                console.error("Fetch Error:", error);
                setLoading(false);
            }
        };

        fetchApprenticeshipData();
    }, []);

    const filterData = (value) => {
        let filteredData = allData;

        if (selectedSector) {
            filteredData = filteredData.filter((item) =>
                item.processedDetails?.some((detail) => {
                    return detail.sector === selectedSector;
                }),
            );
        }
        if (selectedEmirate) {
            filteredData = filteredData.filter(
                (item) => item.EmiratesId === selectedEmirate,
            );
        }

        if (selectedStatus) {
            filteredData = filteredData.filter(
                (item) => item.CategoryName === selectedStatus,
            );
        }

        if (selectedQualificationLevel) {
            filteredData = filteredData.filter((item) =>
                item.processedDetails?.some((detail) => {
                    return (
                        detail.qualificationLevel === selectedQualificationLevel
                    );
                }),
            );
        }

        if (value) {
            filteredData = filteredData.filter(
                (item) =>
                    item.Title?.toLowerCase().includes(value.toLowerCase()) ||
                    item.processedDetails?.some((detail) => {
                        return detail.qualificationLevel
                            .toLowerCase()
                            .includes(value.toLowerCase());
                    }),
            );
        }

        setOpenData({
            totalFiles: filteredData.length,
            data: filteredData,
        });
    };
    const handleApply = () => {
        setFullscreenModal(false);
        setIsOpen(false);
        filterData(searchPhrase);
        changePage(1);
    };

    const handleReset = () => {
        setSelectedEmirate("");
        setSelectedSector("");
        setSelectedStatus("");
        setSelectedQualificationLevel("");
        setSearchPhrase("");
        setSearchValue("");
        setIsOpen(false);
        setFullscreenModal(false);
        setOpenData({
            totalFiles: allData.length,
            data: allData,
        });
        changePage(1);
    };
    const toggleOpen = () => setFullscreenModal(!fullscreenModal);

    const changePage = (textContent) => {
        setCurrentPage(parseInt(textContent));
        $("#s4-workspace").animate({ scrollTop: 0 }, "slow");
    };
    return (
        <MOELayout>
            {loading ? (
                Array(perPage)
                    .fill()
                    .map((item, index) => (
                        <MDBCol className="mb-4" key={index}>
                            <Skeleton
                                key={index}
                                height={72}
                                style={{
                                    width: "100%",
                                    borderRadius: ".5rem",
                                    marginTop: "1.5rem",
                                }}
                            />
                        </MDBCol>
                    ))
            ) : (
                <div className="open-data flex flex-col gap-8 lg:gap-12 pb-12">
                    <div className="flex gap-2 justify-between">
                        <button
                            className=" basis-1/4 bg-transparent p-0 gap-2 flex lg:hidden"
                            onClick={toggleOpen}
                            type="button"
                        >
                            <span>{isArabic ? "بحث" : "Filter"} </span>
                            <i class="ph ph-sliders"></i>
                        </button>

                        <div className="relative" ref={dropdownRef}>
                            <button
                                className="aegov-btn   w-max aegov-btn-filter hidden lg:flex"
                                type="button"
                                onClick={toggleDropdown}
                            >
                                <span>{isArabic ? "بحث" : "Filter"} </span>
                                <i className="ph ph-sliders"></i>
                            </button>

                            {isOpen && (
                                <div
                                    className={`aegov-dropdown absolute rounded-2xl ${isArabic ? "right-0" : "left-0"}  ${
                                        isOpen ? "block" : "hidden"
                                    }`}
                                >
                                    <div className="lg:w-80 p-6 border border-primary-400 rounded-2xl space-y-8 bg-whitely-50">
                                        <section className="space-y-4">
                                            <h6>
                                                {isArabic ? "بحث" : "Filter"}{" "}
                                            </h6>
                                            <div className="space-y-3 max-h-[32rem] overflow-auto">
                                                <div className="flex flex-col gap-4">
                                                    <div className="aegov-form-control">
                                                        <div className="form-control-input">
                                                            <select
                                                                value={
                                                                    selectedStatus ||
                                                                    ""
                                                                }
                                                                onChange={
                                                                    handleStatusChange
                                                                }
                                                            >
                                                                <option value="">
                                                                    {isArabic
                                                                        ? "إختر الحالة"
                                                                        : "Select Status"}
                                                                </option>
                                                                {statuses.map(
                                                                    (
                                                                        item,
                                                                        index,
                                                                    ) => (
                                                                        <option
                                                                            value={
                                                                                isArabic
                                                                                    ? item.statusAr
                                                                                    : item.statusEn
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {isArabic
                                                                                ? item.statusAr
                                                                                : item.statusEn}
                                                                        </option>
                                                                    ),
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="aegov-form-control">
                                                        <div className="form-control-input">
                                                            <select
                                                                value={
                                                                    selectedSector ||
                                                                    ""
                                                                }
                                                                onChange={
                                                                    handleSectorChange
                                                                }
                                                            >
                                                                <option value="">
                                                                    {isArabic
                                                                        ? "إختر القطاع"
                                                                        : "Select Sector"}
                                                                </option>
                                                                {sectors.map(
                                                                    (
                                                                        item,
                                                                        index,
                                                                    ) => (
                                                                        <option
                                                                            value={
                                                                                item.id
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {isArabic
                                                                                ? item.sectorAr
                                                                                : item.sectorEn}
                                                                        </option>
                                                                    ),
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="aegov-form-control">
                                                        <div className="form-control-input">
                                                            <select
                                                                value={
                                                                    selectedEmirate ||
                                                                    ""
                                                                }
                                                                onChange={
                                                                    handleEmirateChange
                                                                }
                                                            >
                                                                <option value="">
                                                                    {isArabic
                                                                        ? "إختر الإمارة"
                                                                        : "Select Emirate"}
                                                                </option>
                                                                {emiratesCBMC
                                                                    .slice()
                                                                    .sort(
                                                                        (
                                                                            a,
                                                                            b,
                                                                        ) => {
                                                                            if (
                                                                                isArabic
                                                                            ) {
                                                                                return a.emirateAr.localeCompare(
                                                                                    b.emirateAr,
                                                                                    "ar",
                                                                                );
                                                                            } else {
                                                                                return a.emirateEn.localeCompare(
                                                                                    b.emirateEn,
                                                                                    "en",
                                                                                );
                                                                            }
                                                                        },
                                                                    )
                                                                    .map(
                                                                        (
                                                                            item,
                                                                            index,
                                                                        ) => (
                                                                            <option
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {isArabic
                                                                                    ? item.emirateAr
                                                                                    : item.emirateEn}
                                                                            </option>
                                                                        ),
                                                                    )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="aegov-form-control">
                                                        <div className="form-control-input">
                                                            <select
                                                                value={
                                                                    selectedQualificationLevel ||
                                                                    ""
                                                                }
                                                                onChange={
                                                                    handleQualificationLevelChange
                                                                }
                                                            >
                                                                <option value="">
                                                                    {isArabic
                                                                        ? "إختر مستوى المؤهل"
                                                                        : "Select Qualification Level"}
                                                                </option>
                                                                {LevelsCBMC.slice(
                                                                    0,
                                                                    8,
                                                                ).map(
                                                                    (
                                                                        item,
                                                                        index,
                                                                    ) => (
                                                                        <option
                                                                            value={
                                                                                isArabic
                                                                                    ? item.levelAr
                                                                                    : item.levelEn
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {isArabic
                                                                                ? item.levelAr
                                                                                : item.levelEn}
                                                                        </option>
                                                                    ),
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between gap-2">
                                                        <button
                                                            onClick={
                                                                handleReset
                                                            }
                                                            className="aegov-btn btn-outline  !text-aeblack-800 !border-aeblack-800 btn-sm flex-1"
                                                            type="button"
                                                        >
                                                            <i className="ph ph-arrow-counter-clockwise"></i>
                                                            {isArabic
                                                                ? "إعادة"
                                                                : "Reset"}
                                                        </button>
                                                        <button
                                                            onClick={
                                                                handleApply
                                                            }
                                                            className="aegov-btn btn-sm flex-1"
                                                            type="button"
                                                            disabled={
                                                                !selectedStatus &&
                                                                !selectedSector &&
                                                                !selectedEmirate &&
                                                                !selectedQualificationLevel
                                                            }
                                                        >
                                                            {isArabic
                                                                ? "تطبيق"
                                                                : "Apply"}

                                                            <i className="ph ph-check"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="aegov-form-control">
                            <div className="form-control-input max-w-[300px]">
                                <input
                                    type="search"
                                    name="search_suffix"
                                    id="search_suffix"
                                    placeholder={
                                        isArabic
                                            ? "البحث فى وحدات التعلم قصيرة المدى"
                                            : "Search in CBMC, Qualification and QualificationLevel"
                                    }
                                    value={searchValue}
                                    onChange={handleSearchChange}
                                />
                                <span className="control-suffix">
                                    <i className="ph ph-magnifying-glass text-xl"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 gap-[16px]">
                        {openData.data.length > 0 ? (
                            openData.data
                                .slice(
                                    (currentPage - 1) * perPage,
                                    currentPage * perPage,
                                )

                                .map((item, index) => (
                                    <div
                                        className="aegov-card card-bordered !border-transparent
                hover:!border
                 hover:!border-aegold-200 hover:bg-primary-50  hover:dark:bg-transparent hover:shadow-[0px_7px_14.9px_2px_#E4D5B6]"
                                        key={index}
                                    >
                                        <div className="flex flex-col  gap-[16px] lg:gap-6 lg:flex-row justify-content-between items-start">
                                            <div className="flex flex-col gap-2 basis-5/12">
                                                <span className="text-aeblack-500  text-xs">
                                                    {" "}
                                                    {isArabic
                                                        ? "اسم المؤسسة"
                                                        : "Organization Name"}
                                                </span>
                                                <span className="text-sm font-semibold">
                                                    {item.Title}
                                                </span>
                                            </div>
                                            <div className="flex flex-col gap-2 basis-3/12">
                                                <span className="text-aeblack-500  text-xs">
                                                    {isArabic
                                                        ? "الإمارة"
                                                        : "Emirate"}
                                                </span>
                                                <span className="text-sm font-semibold">
                                                    {item?.EmiratesName}
                                                </span>
                                            </div>

                                            <div className="flex flex-col gap-2 lg:items-center basis-2/12">
                                                <span className="text-aeblack-500  text-xs">
                                                    {isArabic
                                                        ? "الحالة"
                                                        : "Status"}
                                                </span>
                                                <span
                                                    className={`${getClassName(item.CategoryName)} rounded-2xl text-xs  p-2 text-whitely-50`}
                                                >
                                                    {item.CategoryName}
                                                </span>
                                            </div>

                                            <div className="flex flex-col basis-2/12 lg:self-center lg:items-center mt-2">
                                                <a
                                                    href="#!"
                                                    className="text-sm !text-primary-500 hover:!underline mt-4"
                                                    onClick={() =>
                                                        extractXmlData(
                                                            item.CBMCDetails,
                                                        )
                                                    }
                                                >
                                                    {isArabic
                                                        ? "عرض التفاصيل"
                                                        : "View details"}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <div className="font-bold text-2xl text-center">
                                {isArabic ? "لاتوجد بيانات" : "No Data Found"}
                            </div>
                        )}
                    </div>

                    <MOEPagenation
                        perPage={perPage}
                        total={openData.totalFiles}
                        handlePageClick={(e) =>
                            changePage(e.target.textContent)
                        }
                        currentPage={currentPage}
                    />
                </div>
            )}
            <MDBModal
                show={showModal}
                onHide={() => setShowModal(false)}
                className="assessment-Modal"
            >
                <MDBModalDialog centered size="xl">
                    <MDBModalContent>
                        <button
                            type="button"
                            className="btn-close lg:mt-3"
                            color="none"
                            onClick={() => setShowModal(false)}
                        />
                        <MDBModalBody>
                            <div className="overflow-auto max-h-[70vh]">
                                <table
                                    className={`table table-bordered align-middle  !border-separate border-spacing-1 ${isArabic ? "text-right" : "text-left"}`}
                                >
                                    <thead class="table-light">
                                        <tr>
                                            <th className="!bg-aeblack-100 dark:!bg-gray-800 border-none rounded-ss-lg font-semibold text-sm">
                                                {isArabic
                                                    ? "اسم وحدة التعلم قصيرة المدى"
                                                    : "Micro-credentials title"}
                                            </th>
                                            <th className="!bg-aeblack-100 dark:!bg-gray-800 border-none font-semibold text-sm">
                                                {isArabic
                                                    ? "إجمالي ساعات الدراسة"
                                                    : "Total Credit Hour"}
                                            </th>
                                            <th className="!bg-aeblack-100 dark:!bg-gray-800 border-none font-semibold text-sm">
                                                {isArabic
                                                    ? "المستوى"
                                                    : "QF Emirates Level"}
                                            </th>
                                            <th className="!bg-aeblack-100 dark:!bg-gray-800 border-none rounded-se-lg font-semibold text-sm">
                                                {isArabic ? "القطاع" : "Sector"}
                                            </th>
                                            {/* <th className="!bg-aeblack-100 border-none rounded-se-lg">{isArabic ? 'الحالة' : 'Status'}</th>  */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {qualificationData &&
                                            qualificationData.map((data) => (
                                                <tr key={data.id}>
                                                    <td className="!bg-aeblack-50 dark:!bg-gray-900 text-sm  border-none">
                                                        {data.qualificationName}
                                                    </td>
                                                    <td className="!bg-aeblack-50 dark:!bg-gray-900 text-sm border-none">
                                                        {data.totalCreditHour}
                                                    </td>
                                                    <td className="!bg-aeblack-50 dark:!bg-gray-900 text-sm  border-none">
                                                        {
                                                            data.qualificationLevel
                                                        }
                                                    </td>

                                                    <td className="!bg-aeblack-50 dark:!bg-gray-900  text-sm border-none">
                                                        {data.sectorName}
                                                    </td>
                                                    {/* <td className="!bg-aeblack-50 border-none">
                            <span className={`${getClassName(data.statusEN)} rounded-2xl text-xs py-2 px-4 text-whitely-50`}>
                              {isArabic ? data.statusAR : data.statusEN}
                            </span>
                          </td> */}
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            <MDBModal
                show={fullscreenModal}
                onHide={() => setFullscreenModal(false)}
                className="assessment-Modal"
            >
                <MDBModalDialog size="fullscreen">
                    <MDBModalContent>
                        <div className="flex text-aeblack-900 dark:!text-whitely-50  text-lg mb-3">
                            <i
                                className={`ph ${isArabic ? "ph-caret-right" : "ph-caret-left"}`}
                                onClick={toggleOpen}
                            ></i>
                            <p className="mx-auto text-sm">
                                {isArabic
                                    ? "تصفية حسب الفئات"
                                    : "Filter by categories"}
                            </p>
                        </div>

                        <MDBModalBody>
                            <h6 className="text-xl">
                                {isArabic ? "الحالة" : "Status"}
                            </h6>
                            <div className="flex gap-1 flex-wrap">
                                <span
                                    className={`border border-aeblack-500 w-fit p-2 text-sm rounded-3xl cursor-pointer hover:bg-aeblack-500 ${!selectedStatus ? "bg-aeblack-500 text-white" : ""}`}
                                    onClick={() =>
                                        handleStatusChange({
                                            target: { value: "" },
                                        })
                                    }
                                >
                                    {isArabic ? "الكل" : "All"}
                                </span>

                                {statuses.map((item, index) => (
                                    <span
                                        key={index}
                                        className={`border border-aeblack-500 w-fit p-2 text-sm rounded-3xl cursor-pointer  hover:bg-aeblack-500 ${selectedStatus === (isArabic ? item.statusAr : item.statusEn) ? "bg-aeblack-500 text-white" : ""}`}
                                        onClick={() =>
                                            handleStatusChange({
                                                target: {
                                                    value: isArabic
                                                        ? item.statusAr
                                                        : item.statusEn,
                                                },
                                            })
                                        }
                                    >
                                        {isArabic
                                            ? item.statusAr
                                            : item.statusEn}
                                    </span>
                                ))}
                            </div>
                            <hr className="my-4 text-aeblack-100 opacity-100 opacity-100" />
                            <h6 className="text-xl">
                                {isArabic ? "القطاع" : "sector"}
                            </h6>
                            <div className="flex gap-1 flex-wrap">
                                <span
                                    className={`border border-aeblack-500 w-fit p-2 text-sm rounded-3xl cursor-pointer hover:bg-aeblack-500  ${!selectedSector ? "bg-aeblack-500 text-white" : ""}`}
                                    onClick={() =>
                                        handleSectorChange({
                                            target: { value: "" },
                                        })
                                    }
                                >
                                    {isArabic ? "الكل" : "All"}
                                </span>

                                {sectors.map((item, index) => (
                                    <span
                                        key={index}
                                        className={`border border-aeblack-500 w-fit p-2 text-sm rounded-3xl cursor-pointer hover:bg-aeblack-500 ${selectedSector === item.id ? "bg-aeblack-500 text-white" : ""}`}
                                        onClick={() =>
                                            handleSectorChange({
                                                target: { value: item.id },
                                            })
                                        }
                                    >
                                        {isArabic
                                            ? item.sectorAr
                                            : item.sectorEn}
                                    </span>
                                ))}
                            </div>
                            <hr className="my-4 text-aeblack-100 opacity-100" />
                            <h6 className="text-xl">
                                {isArabic ? "الإمارة" : "Emirate"}
                            </h6>
                            <div className="flex gap-1 flex-wrap">
                                <span
                                    className={`border border-aeblack-500 w-fit p-2 text-sm rounded-3xl cursor-pointer hover:bg-aeblack-500 ${!selectedEmirate ? "bg-aeblack-500 text-white" : ""}`}
                                    onClick={() =>
                                        handleEmirateChange({
                                            target: { value: "" },
                                        })
                                    }
                                >
                                    {isArabic ? "الكل" : "All"}
                                </span>

                                {emiratesCBMC
                                    .slice()
                                    .sort((a, b) => {
                                        if (isArabic) {
                                            return a.emirateAr.localeCompare(
                                                b.emirateAr,
                                                "ar",
                                            );
                                        } else {
                                            return a.emirateEn.localeCompare(
                                                b.emirateEn,
                                                "en",
                                            );
                                        }
                                    })
                                    .map((item, index) => (
                                        <span
                                            key={index}
                                            className={`border border-aeblack-500 w-fit p-2 text-sm rounded-3xl cursor-pointer hover:bg-aeblack-500 ${selectedEmirate === item.id ? "bg-aeblack-500 text-white" : ""}`}
                                            onClick={() =>
                                                handleEmirateChange({
                                                    target: { value: item.id },
                                                })
                                            }
                                        >
                                            {isArabic
                                                ? item.emirateAr
                                                : item.emirateEn}
                                        </span>
                                    ))}
                            </div>
                            <hr className="my-4 text-aeblack-100 opacity-100" />
                            <h6 className="text-xl">
                                {isArabic
                                    ? "مستوى المؤهل"
                                    : "Qualification Level"}
                            </h6>
                            <div className="flex gap-1 flex-wrap">
                                <span
                                    className={`border border-aeblack-500 w-fit p-2 text-sm rounded-3xl cursor-pointer hover:bg-aeblack-500 ${!selectedQualificationLevel ? "bg-aeblack-500 text-white" : ""}`}
                                    onClick={() =>
                                        handleQualificationLevelChange({
                                            target: { value: "" },
                                        })
                                    }
                                >
                                    {isArabic ? "الكل" : "All"}
                                </span>

                                {LevelsCBMC.map((item, index) => (
                                    <span
                                        key={index}
                                        className={`border border-aeblack-500 w-fit p-2 text-sm rounded-3xl cursor-pointer hover:bg-aeblack-500 ${selectedQualificationLevel === (isArabic ? item.levelAr : item.levelEn) ? "bg-aeblack-500 text-white" : ""}`}
                                        onClick={() =>
                                            handleQualificationLevelChange({
                                                target: {
                                                    value: isArabic
                                                        ? item.levelAr
                                                        : item.levelEn,
                                                },
                                            })
                                        }
                                    >
                                        {isArabic ? item.levelAr : item.levelEn}
                                    </span>
                                ))}
                            </div>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <div className="flex flex-col gap-2 w-full">
                                <button
                                    onClick={() => {
                                        handleApply();
                                        setFullscreenModal(false);
                                    }}
                                    className="aegov-btn btn-lg"
                                    type="button"
                                >
                                    {isArabic ? "عرض النتائج" : "See Results"}
                                </button>
                                <button
                                    onClick={handleReset}
                                    className="aegov-btn btn-outline  btn-lg"
                                    type="button"
                                >
                                    {isArabic ? "إعادة" : "Reset"}
                                </button>
                            </div>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </MOELayout>
    );
};
export default CBMCPage;
