
import {applyMiddleware, createStore} from 'redux';
import {combineReducers} from 'redux';
import accessabilityReducer from './reducer/accessabilityReducer';
import {checkStatusReducer} from './reducer/checkStatusReducer';
import generalReducer from './reducer/generalReducer';
import {servicesReducer} from './reducer/servicesReducer';
import {preAssessmentReducer} from './reducer/preAssessmentReducer';
import schoolsReducer from './reducer/schoolsReducers';
import {contactReducer} from './reducer/contactReducer';
import {thunk} from 'redux-thunk';
import {serviceCardReducer} from './reducer/serviceCardReducer';
import {faqsReducer} from './reducer/faqsReducer';
import legislationReducer from './reducer/legislationReducer';


const rootReducer = combineReducers({
    accessability: accessabilityReducer,
    general: generalReducer,
    services: servicesReducer,
    checkStatus: checkStatusReducer,
    preAssessment: preAssessmentReducer,
    schools: schoolsReducer,
    contact: contactReducer,
    serviceCard: serviceCardReducer,
    faq: faqsReducer,
    legislation: legislationReducer,
});



// const store = createStore(rootReducer);
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;