/* eslint-disable import/no-unused-modules */
import {SET_LAWS, SET_LAW_CATEGORIES, SET_SELECTED_CATEGORY, SET_SELECTED_YEAR, SET_SEARCH, SET_LOADING, SET_ERROR, RESET_FILTERS, APPLY_FILTERS, FILTER_LAWS, UPDATE_SEARCH} from "../actions/legislationActions";

/* eslint-disable default-param-last */
const initialState = {
    laws: [],
    filteredLaws: [],
    MinisterialDecrees: [],
    FederalLaws: [],
    InstitutionalPolicies: [],
    FederalDecrees: [],
    lawCategories: [],
    selectedCategory: "",
    selectedYear: "",
    search: "",
    loading: false,
    error: null,
    START_YEAR: 2000,
    END_YEAR: new Date().getFullYear(),
};

const legislationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LAWS:
            return {...state, ...action.payload};
        case SET_LAW_CATEGORIES:
            return {...state, lawCategories: action.payload};
        case SET_SELECTED_CATEGORY:
            return {...state, selectedCategory: action.payload};
        case SET_SELECTED_YEAR:
            return {...state, selectedYear: action.payload};
        case SET_SEARCH:
            return {...state, search: action.payload};
        case SET_LOADING:
            return {...state, loading: action.payload};
        case SET_ERROR:
            return {...state, error: action.payload};
        case FILTER_LAWS:
            return {...state, laws: action.payload};
        case RESET_FILTERS:
            return {...state, ...initialState};
        case APPLY_FILTERS:
            return {
                ...state,
                ...action.payload,
            };
        case UPDATE_SEARCH:
            return {...state, search: action.payload};
        default:
            return state;
    }
};

export default legislationReducer;
