import {siteURL} from "../constants/constants";

/**
 * Gets the file size of a file from its URL using a HEAD request
 * @param {string} url - The URL of the file
 * @returns {Promise<number|null>} The file size in bytes or null if there's an error
 */
export const getURLFileSize = async (url) => {
    try {
        const response = await fetch(siteURL + url, {method: "HEAD"});
        const contentLength = response.headers.get("Content-Length");

        return contentLength ? parseInt(contentLength, 10) : null;
    } catch (error) {
        console.error("Error fetching file size for:", url, error);
        return null;
    }
};