/* eslint-disable no-nested-ternary */
// include jQuery and Bootstrap
import $ from "jquery";

const {REACT_APP_IS_LIVE, REACT_APP_BASE_URL} = process.env;
window.jQuery = $;
window.$ = $;

// Name: main.js
// Description: Main JS file for the project

var isArabic = false;
let isLive = REACT_APP_IS_LIVE === "true" || false

let siteURL = REACT_APP_BASE_URL;

let baseURL = "";

//======================================================
// Get Language from _spPageContextInfo object
//======================================================
(function getLang() {
    // Check if _spPageContextInfo is available in window object
    let language = window._spPageContextInfo ? window?._spPageContextInfo?.currentLanguage === 1025 ? "ar" : "en" : localStorage.getItem("pmo_service_observatory")
        ? JSON.parse(localStorage.getItem("pmo_service_observatory")).language
        : "en";
    // if (window._spPageContextInfo) is true, then check if the current language is Arabic
    if (language === "ar") {
        // RTL Layout
        isArabic = true;
        $("body").addClass("rtl");
        baseURL = `${siteURL}ar`;
        return "ar";
    }
    isArabic = false;
    $("body").removeClass("rtl");
    baseURL = `${siteURL}en`;
    return "en";

})();

// window object
const siteConfig = {
    siteLogo: "/assets/images/logo.png",
    siteLogoAr: "/assets/images/logo-ar.png",
    iosLogo: {
        sohw: true,
        logo: "/assets/images/logo.png",
        url: "https://apps.apple.com/ae/app/moe-uae/id1479788849",
    },
    androidLogo: {
        show: true,
        logo: "/assets/images/logo.png",
        url: "https://play.google.com/store/apps/details?id=com.moe.moeapp",
    },
};

const layout = {
    home_page: {
        showAnnouncements: true,
        showServices: false,
        showCategories: true,
        showNews: true,
        showEvents: false,
    },
};

const paginationConfig = {
    MediaItemsPerPage: 6,
    ServicesItemsPerPage: 9,
    NewsItemsPerPage: 3,
    EventsItemsPerPage: 3,
};


const filterServiceBar = [
    {
        id: 1,
        slug: "all",
        title: "All Services",
        titleAr: "كل الخدمات",
        active: true,
    },
    {
        id: 2,
        slug: "corporates",
        title: "Corporates",
        titleAr: "الشركات",
        active: false,
    },
    {
        id: 3,
        slug: "individuals",
        title: "Individuals",
        titleAr: "الأفراد",
        active: false,
    },
    {
        id: 4,
        slug: "popular",
        title: "Popular services",
        titleAr: "الخدمات الشائعة",
        active: false,
    },
];

//======================================================
// LAnguage Switcher
//======================================================
const data = [
    {id: 1, categoryName: "xyz"},
    {id: 2, categoryName: "abc"},
    {id: 3, categoryName: "xyz"},
    {id: 4, categoryName: "def"},
    // ... other objects
];

//===============================
// Call Ajax to get page content
//===============================
function callAjax(url, callback) {
    try {
        $.ajax({
            url: url,
            type: "GET",
            headers: {
                accept: "application/json;odata=verbose",
            },
            success: async (data) => {
                await callback(data.d.results);
            },
            error: (error) => {
                console.log("Error in callAjax: ", error);
            },
        });
    } catch (error) {
        console.log("Catch Error in callAjax: ", error);
    }
}

//======================================================
// Toggle Search Container
//======================================================
$(document).on("click", ".search-toggle", (e) => {
    e.preventDefault();
    $(".top-header .search-container").toggleClass("d-none");
});
$(document).on("click", "#logoutsp", (e) => {
    e.preventDefault();
    deleteCookie('userInfo');
    window.location.href = '/Pages/Signout.aspx';

});
function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`;

}

//======================================================
// Toggle Side Menu
//======================================================

$(document).on("click", ".side-menu-toggle", (e) => {
    e.preventDefault();
    $(".site-map").toggleClass("show");
});

$("body").on("click", (e) => {
    if ($(e.target).closest(".site-map,.side-menu-toggle").length === 0) {
        if ($(".site-map").hasClass("show")) {
            $(".site-map").removeClass("show");
        }
    }

    // Toggle Search Container on click outside, but not on search-container itself
    if (
        $(e.target).closest(".top-header .search-container").length === 0 &&
        !$(e.target).hasClass("search-toggle")
    ) {
        $(".top-header .search-container").addClass("d-none");
    }
});

//======================================================
// Translate Page Using Google Translate
//======================================================
function googleTranslateElementInit() {
    try {
        new google.translate.TranslateElement(
            {
                pageLanguage: isArabic ? "ar" : "en",
                gaTrack: true,
                layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
                autoDisplay: false,
            },
            "google_translate_element"
        );
    } catch (error) {
        console.log(error);
    }
}

//======================================================
// Collapse Toggle based on [data-toggle="collapse"]
//======================================================
$(document).on("click", '[data-toggle="collapse"]', function (e) {
    e.preventDefault();
    // Get target collapse element
    const target = $(this).attr("href");
    // Toggle collapse class
    $(target).toggleClass("show");
    // Close all other collapse
    $(this)
        .closest(".collapse")
        .find(".collapse")
        .not(target)
        .removeClass("show");
    // Set height to auto
    $(this).closest(".collapse").attr("style", "height: auto");
});

//======================================================
// Enable Bootstrap Tooltip based on [data-bs-toggle="tooltip"]
//======================================================
let tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
);
let tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));

//======================================================
// Remove &ZeroWidthSpace from any string
//======================================================
let exitSite = "";
if (
    window._spPageContextInfo &&
    window._spPageContextInfo.currentLanguage === 1025
) {
    exitSite =
        "يوجهك هذا الرابط إلى موقع خارجي قد تكون له سياسات مختلفة للمحتوى والخصوصية عن موقع وزارة التعليم العالي والبحث العلمي";
} else {
    exitSite =
        "This link will direct you to an external website that may have different content and privacy policies than MoE website";
}

window.checkPageTitle = function () {
    // check page <title></title>
    const title = document.querySelector("title");
    // remove all spaces
    const titleWithoutSpaces = title.innerText.replace(/\s/g, "");
    // After removing spaces, check if the title is empty
    if (titleWithoutSpaces === "") {
        title.innerText = isArabic
            ? "وزارة التعليم العالي والبحث العلمي"
            : "MOHESR";
    }
};

$(window).on("load", () => {
    if ($("#moe-loader")) {
        $("#moe-loader").fadeOut(100, function () {
            $(this).remove();
        });
    }
});

const url = window.location.href;

const isMobileDevice = function () {
    const userAgent = window.navigator.userAgent.toLowerCase();

    return /iphone|ipad|ipod|android|webos|iemobile|opera mini/i.test(userAgent);
}

$(document).on("click", ".fa-share-alt", (e) => {

    if (navigator.share && isMobileDevice()) {
        // Web Share API is supported

        try {
            navigator.share({
                title: '',
                text: '',
                url: url,
            });
        } catch (error) {
            // Handle share error
            console.error('Error sharing:', error);
        }
    } else {
        $(".shareServiceModal").modal("show");

    }
});

window.siteConfig = siteConfig;
window.layout = layout;
window.paginationConfig = paginationConfig;
// window.servicesCategoriesIcon = servicesCategoriesIcon;
window.filterServiceBar = filterServiceBar;
window.callAjax = callAjax;
window.googleTranslateElementInit = googleTranslateElementInit;
window.isArabic = isArabic;
window.isLive = isLive;
window.siteURL = siteURL;
window.baseURL = baseURL;
