
import React, {useState} from "react";
import MOELayout from '../../layouts/MOELayout'
import NQSApprenticeshipPage from "./apprenticeship";
import NQSQualificationsPage from "./qualifications";
import CBMCPage from "./CBMC";

const AccreditedQualificationsPage = () => {
  const accreditedQualificationsList = [
    {id: 0, titleAr: 'المؤهلات', titleEn: 'Qualifications'},
    {id: 1, titleAr: 'التلمذة المهنية', titleEn: 'Apprenticeship'},
    {id: 2, titleAr: 'وحدات التعلم القصيرة', titleEn: 'CBMC'}
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };



  return (

    <MOELayout>

      <div className="flex flex-col lg:flex-row gap-3">
        <div className="categories-filter-container flex flex-row lg:!flex-col gap-2 basis-1/6 overflow-x-auto">

          {accreditedQualificationsList.map((item) => (
            <button
              key={item.id}
              type="button"
              className={`aegov-btn w-fit lg:w-full justify-start text-sm lg:text-base gap-4 lg:px-0 ${activeIndex === item.id ? 'active' : 'btn-link'}`}
              onClick={() => handleButtonClick(item.id)}
            >
              <span />
              {isArabic ? item.titleAr : item.titleEn}
            </button>
          ))}


        </div>
        <div className="open-data flex flex-col gap-4 pb-12 basis-5/6">
          {activeIndex === 0 && <NQSQualificationsPage />}
          {activeIndex === 1 && <NQSApprenticeshipPage />}
          {activeIndex === 2 && <CBMCPage />}
        </div>
      </div>
    </MOELayout >
  );
};

export default AccreditedQualificationsPage;
