import {MDBContainer, MDBRow} from 'mdb-react-ui-kit';
import React from 'react'
import MOELayout from "../../layouts/MOELayout";
import NQSList from '../../components/NQSList';
import EducationalConsultingList from '../../components/TrainingِAndEducational/EducationalConsultingList';

const EducationalConsultingPage = () => {
  return (
    <MOELayout pageClassName='service-card-page'>

      <EducationalConsultingList />
    </MOELayout>
  )
}

export default EducationalConsultingPage