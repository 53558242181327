import React from "react";
import UAE_LEGISLATION_LOGO from "../../../assets/images/uae_legislation_logo.png";

const LegislationLogo = () => (
    <div className="flex lg:justify-start justify-center items-center mt-4">
        <a
            href="https://uaelegislation.gov.ae/en"
            target="_blank"
            rel="noreferrer"
        >
            <img
                src={UAE_LEGISLATION_LOGO}
                alt={
                    isArabic
                        ? "تشريعات الإمارات العربية المتحدة"
                        : "UAE Legislation"
                }
                className="w-[200px] dark:invert"
            />
        </a>
    </div>
);

export default LegislationLogo;
