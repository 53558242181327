/* eslint-disable node/no-missing-import */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from "react-awesome-reveal";
import { fetchLaws } from "../../../redux/actions/legislationActions";
import LegislationCard from "./LegislationCard";
import LegislationLogo from "./LegislationLogo";

const DLSLegislation = ({ data, title = "" }) => {
    const { filteredLaws, loading, error } = useSelector(
        (state) => state.legislation,
    );
    const listData = data || filteredLaws;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchLaws());
    }, [dispatch]);

    // Loading state
    if (loading) {
        return (
            <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-2">
                    {Array.from({ length: 10 }).map((_, index) => (
                        <Skeleton
                            key={index}
                            className="h-[100px] rounded-xl w-full"
                        />
                    ))}
                </div>
            </div>
        );
    }

    // Error state
    if (error) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <div
                    className="alert alert-danger text-center md:w-1/2 w-full"
                    role="alert"
                >
                    <i className="ph ph-warning text-3xl" aria-hidden="true" />
                    <h5 className="alert-heading">
                        {isArabic ? "حدث خطأ" : "Error Occurred"}
                    </h5>
                    {isArabic ? "حدث خطأ" : "Error Occurred"}: {error?.message}
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-8">
            <div className="flex justify-between items-center">
                {title && (
                    <h2 className="text-2xl mt-4 mb-2 font-semibold">
                        {title}
                    </h2>
                )}

                <div className="flex text-sm text-gray-500 m-0 items-center gap-2">
                    {isArabic ? "نتائج " : "Results"}
                    <span className="text-gray-500">{listData.length}</span>
                </div>
            </div>
            {/* Legislation Container */}
            {listData.length > 0 ? (
                <div className="legislation-container flex flex-col gap-4">
                    <Fade direction="top" cascade damping={0.1} triggerOnce>
                        {listData.map((law) => (
                            <LegislationCard key={law.ID} data={law} />
                        ))}
                    </Fade>
                </div>
            ) : (
                <div className="aegov-alert alert-warning flex justify-center items-center">
                    <div className="alert-icon">
                        <i
                            className="ph-fill ph-warning text-3xl"
                            aria-hidden="true"
                        />
                    </div>
                    <div className="alert-content">
                        <p className="text-2xl font-semibold">
                            {isArabic
                                ? `لا يوجد مواد قانونية ${title}`
                                : `No Legislation "${title}" Found`}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DLSLegislation;
