import React, { useState } from "react";
import LegislationsPage from "./Legislations";
import AccreditedQualificationsPage from "./accreditedQualifications";
import NQCAboutPage from "./about";
import NQCAccreditedTrainingProvidersPage from "./accreditedTrainingProviders";
import NQSAwardingPage from "./awardingBodies";

const NQSFilterTabs = () => {
    const [activeTab, setActiveTab] = useState("about");
    const NQSList = [
        {
            titleEn: "About",
            titleAr: "نبذة",
            slug: "about",
            component: <NQCAboutPage />,
        },
        {
            titleEn: "Accredited Training Providers",
            titleAr: "مؤسسات التعليم و التدريب",
            slug: "accredited",
            component: <NQCAccreditedTrainingProvidersPage />,
        },
        {
            titleEn: "Accredited Qualifications",
            titleAr: "المؤهلات",
            slug: "qualifications",
            component: <AccreditedQualificationsPage />,
        },
        {
            titleEn: "Awarding Bodies",
            titleAr: "الجهات المانحة",
            slug: "awarding",
            component: <NQSAwardingPage />,
        },
        {
            titleEn: "Legislations & Guides",
            titleAr: "تشريعات و أدلة",
            slug: "legislations",
            component: <LegislationsPage />,
        },
    ];
    const handleTabClick = (slug) => {
        setActiveTab(slug);
    };
    return (
        <div className="flex flex-col gap-8 lg:gap-12">
            <div className="aegov-tab tab-pills overflow-x-auto py-2 w-full">
                <ul className="tab-items flex gap-4 md:gap-6 lg:gap-7 xl:gap-8 max-xl:overflow-auto">
                    {NQSList.map((item, index) => {
                        const { titleAr, titleEn, slug } = item;
                        return (
                            <li
                                role="presentation"
                                className=""
                                key={index}
                                onClick={() => handleTabClick(slug)}
                            >
                                <button
                                    type="button"
                                    className={`tab-link whitespace-nowrap h-10 lg:h-12 px-4 lg:px-6 ${activeTab === slug ? "active tab-active" : "!text-gray-500 tab-inactive"}`}
                                >
                                    {isArabic ? titleAr : titleEn}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>

            <div className="tab-content p-0">
                {NQSList.map((item) => {
                    return item.slug === activeTab ? (
                        <div key={item.slug}>{item.component}</div>
                    ) : null;
                })}
            </div>
        </div>
    );
};

export default NQSFilterTabs;
