import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBCol,
} from "mdb-react-ui-kit";
import React, { useEffect, useState, useRef } from "react";
import { NQSGatewayLink } from "@constants/constants";
import MOELayout from "../../layouts/MOELayout";
import MOEPagenation from "@components/MOEPagenation";
import { emiratesATP, status } from "./data";
import { createAxiosInstance } from "../../api";
const NQCAccreditedTrainingProvidersPage = () => {
    const getClassName = (val) => {
        switch (val) {
            case "Active":
            case "مفعل":
                return "bg-aegreen-700";
            default:
                return "bg-aered-700";
        }
    };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const [isMobileView, setIsMobileView] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [openData, setOpenData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [qualificationData, setQualificationData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [fullscreenModal, setFullscreenModal] = useState(false);
    const [searchPhrase, setSearchPhrase] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [selectedEmirateId, setSelectedEmirateId] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(0);

    const [triggerFetch, setTriggerFetch] = useState(false);
    const [loading, setLoading] = React.useState(true);
    const [loadingTable, setLoadingTable] = React.useState(true);
    const dropdownRef = useRef(null);
    const debounceTimeout = useRef(null);
    const handleEmirateIdChange = (event) => {
        setSelectedEmirateId(event.target.value);
    };
    const customAxios = createAxiosInstance("https://webapi.nqa.gov.ae/api");
    const handleViewClick = async (item) => {
        try {
            setLoadingTable(true);

            const response = await customAxios.get(
                `/Integration/nqcwebsite/qualifications/${item.id}`,
                {
                    params: {
                        searchPhrase: "",
                        emirateId: null,
                        pageIndex: 1,
                        pageSize: 100,
                    },
                },
            );
            setQualificationData(response.data.content);
            setLoadingTable(false);
            setShowModal(true);
        } catch (error) {
            setLoadingTable(false);
            console.error("Error fetching qualification data:", error);
        }
    };
    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };
    const handleDocumentClick = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };
    if (typeof window !== "undefined") {
        document.onclick = handleDocumentClick;
    }
    const fetchData = async () => {
        try {
            setLoading(true);

            const pageIndex = currentPage;
            const searchQuery = searchPhrase
                ? `&searchPhrase=${searchPhrase}`
                : "";
            const emirateId = selectedEmirateId
                ? `&emirateId=${selectedEmirateId}`
                : "";
            const StatusParam =
                selectedStatus === "" || selectedStatus === null
                    ? ""
                    : `&status=${selectedStatus}`;
            const apiUrl = `${NQSGatewayLink}api/Integration/nqcwebsite/rtps?pageIndex=${pageIndex}&pageSize=${perPage}${searchQuery}${emirateId}${StatusParam}`;
            const response = await customAxios.get(apiUrl);
            const sortedData = response.data.content.sort((a, b) =>
                (isArabic
                    ? a?.organizationNameAR
                    : a?.organizationNameEN
                ).localeCompare(
                    isArabic ? b?.organizationNameAR : b?.organizationNameEN,
                    undefined,
                    { sensitivity: "base" },
                ),
            );
            setOpenData({
                totalFiles: response.data.total,
                data: sortedData,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error.message);
        }
    };

    // UPDATE MOBILE VIEW ON RESIZE
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 1024);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        fetchData();
    }, [currentPage, perPage, searchPhrase, triggerFetch]);
    const handleApply = () => {
        setFullscreenModal(false);
        setTriggerFetch(!triggerFetch);
        changePage(1);
        setIsOpen(false);
    };
    const handleReset = () => {
        setSelectedEmirateId("");
        setSelectedStatus("");
        setSearchPhrase("");
        setSearchValue("");
        setIsOpen(false);
        setFullscreenModal(false);
        changePage(1);
        setTriggerFetch(!triggerFetch);
    };
    const toggleOpen = () => setFullscreenModal(!fullscreenModal);
    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            setSearchPhrase(value);
            setCurrentPage(1);
        }, 1000);
    };

    const changePage = (textContent) => {
        setCurrentPage(parseInt(textContent));
        $("#s4-workspace").animate({ scrollTop: 0 }, "slow");
    };
    return (
        <MOELayout>
            {loading ? (
                Array(perPage)
                    .fill()
                    .map((item, index) => (
                        <MDBCol className="mb-4" key={index}>
                            <Skeleton
                                key={index}
                                height={72}
                                style={{
                                    width: "100%",
                                    borderRadius: ".5rem",
                                    marginTop: "1.5rem",
                                }}
                            />
                        </MDBCol>
                    ))
            ) : (
                <div className="open-data flex flex-col gap-8 lg:gap-12 pb-12">
                    <div className="flex gap-2 justify-between">
                        <button
                            className=" basis-1/4 bg-transparent p-0 gap-2 flex lg:hidden"
                            onClick={toggleOpen}
                            type="button"
                        >
                            <span>{isArabic ? "بحث" : "Filter"} </span>
                            <i class="ph ph-sliders"></i>
                        </button>

                        <div className="relative" ref={dropdownRef}>
                            <button
                                className="aegov-btn   w-max aegov-btn-filter hidden lg:flex"
                                type="button"
                                onClick={toggleDropdown}
                            >
                                <span>{isArabic ? "بحث" : "Filter"} </span>
                                <i className="ph ph-sliders"></i>
                            </button>

                            {isOpen && (
                                <div
                                    className={`aegov-dropdown absolute rounded-2xl ${isArabic ? "right-0" : "left-0"}  ${
                                        isOpen ? "block" : "hidden"
                                    }`}
                                >
                                    <div className="lg:w-80 p-6 border border-primary-400 rounded-2xl space-y-8 bg-whitely-50">
                                        <section className="space-y-4">
                                            <h6>
                                                {isArabic ? "بحث" : "Filter"}{" "}
                                            </h6>
                                            <div className="space-y-3 max-h-[32rem] overflow-auto">
                                                <div className="flex flex-col gap-4">
                                                    <div className="aegov-form-control">
                                                        <div className="form-control-input">
                                                            <select
                                                                value={
                                                                    selectedEmirateId ||
                                                                    ""
                                                                }
                                                                onChange={
                                                                    handleEmirateIdChange
                                                                }
                                                            >
                                                                <option
                                                                    className="dark:!bg-gray-800"
                                                                    value=""
                                                                >
                                                                    {isArabic
                                                                        ? "اختر الإمارة"
                                                                        : "Select Emirate"}
                                                                </option>
                                                                {emiratesATP
                                                                    .slice()
                                                                    .sort(
                                                                        (
                                                                            a,
                                                                            b,
                                                                        ) => {
                                                                            if (
                                                                                isArabic
                                                                            ) {
                                                                                return a.nameAr.localeCompare(
                                                                                    b.nameAr,
                                                                                    "ar",
                                                                                );
                                                                            } else {
                                                                                return a.nameEn.localeCompare(
                                                                                    b.nameEn,
                                                                                    "en",
                                                                                );
                                                                            }
                                                                        },
                                                                    )

                                                                    .map(
                                                                        (
                                                                            item,
                                                                            index,
                                                                        ) => (
                                                                            <option
                                                                                className="dark:!bg-gray-800"
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {isArabic
                                                                                    ? item.nameAr
                                                                                    : item.nameEn}
                                                                            </option>
                                                                        ),
                                                                    )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="aegov-form-control">
                                                        <div className="form-control-input">
                                                            <select
                                                                value={
                                                                    selectedStatus
                                                                }
                                                                onChange={
                                                                    handleStatusChange
                                                                }
                                                            >
                                                                <option
                                                                    className="dark:!bg-gray-800"
                                                                    value=""
                                                                >
                                                                    {isArabic
                                                                        ? "إختر الحالة"
                                                                        : "Select Status"}
                                                                </option>
                                                                {status.map(
                                                                    (
                                                                        item,
                                                                        index,
                                                                    ) => (
                                                                        <option
                                                                            className="dark:!bg-gray-800"
                                                                            value={
                                                                                item.id
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {isArabic
                                                                                ? item.statusAr
                                                                                : item.statusEn}
                                                                        </option>
                                                                    ),
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between gap-2">
                                                        <button
                                                            onClick={
                                                                handleReset
                                                            }
                                                            className="aegov-btn btn-outline  !text-aeblack-800 !border-aeblack-800 btn-sm flex-1"
                                                            type="button"
                                                        >
                                                            <i className="ph ph-arrow-counter-clockwise"></i>
                                                            {isArabic
                                                                ? "إعادة"
                                                                : "Reset"}
                                                        </button>
                                                        <button
                                                            onClick={
                                                                handleApply
                                                            }
                                                            className="aegov-btn btn-sm flex-1"
                                                            type="button"
                                                            disabled={
                                                                selectedStatus ===
                                                                    "" &&
                                                                !selectedEmirateId
                                                            }
                                                        >
                                                            {isArabic
                                                                ? "تطبيق"
                                                                : "Apply"}

                                                            <i className="ph ph-check"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="aegov-form-control">
                            <div className="form-control-input max-w-[300px]">
                                <input
                                    type="search"
                                    name="search_suffix"
                                    id="search_suffix"
                                    value={searchValue}
                                    placeholder={
                                        isArabic
                                            ? "البحث في مؤسسات التعليم و التدريب"
                                            : "Search in Accredited Training Providers"
                                    }
                                    onChange={handleSearchChange}
                                />
                                <span className="control-suffix">
                                    <i className="ph ph-magnifying-glass text-xl"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 gap-[16px]">
                        {openData.data.length > 0 ? (
                            openData.data.map((item, index) => (
                                <div
                                    className="aegov-card card-bordered !border-transparent
                hover:!border
                 hover:!border-aegold-200 hover:bg-primary-50  hover:dark:bg-transparent  hover:shadow-[0px_7px_14.9px_2px_#E4D5B6]
                "
                                    key={index}
                                >
                                    <div className="flex flex-col  gap-[16px] lg:gap-6 lg:flex-row justify-content-between items-start">
                                        <div className="flex flex-col gap-2 basis-2/12">
                                            <span className="text-aeblack-500  text-xs">
                                                {isArabic ? "الرمز" : "Code"}
                                            </span>
                                            <span className="text-sm font-semibold">
                                                {item.organizationRoleCode}
                                            </span>
                                        </div>
                                        <div className="flex flex-col gap-2 basis-4/12">
                                            <span className="text-aeblack-500  text-xs">
                                                {isArabic ? "الاسم" : "Name"}
                                            </span>
                                            <span className="text-sm font-semibold">
                                                {isArabic
                                                    ? item.organizationNameAR
                                                    : item.organizationNameEN}
                                            </span>
                                        </div>

                                        <div className="flex flex-col gap-2 basis-2/12">
                                            <span className="text-aeblack-500  text-xs">
                                                {isArabic
                                                    ? "الإمارة"
                                                    : "Emirate"}
                                            </span>
                                            <span className="text-sm font-semibold">
                                                {isArabic
                                                    ? item.emiratesAR
                                                    : item.emiratesEN}
                                            </span>
                                        </div>

                                        <div className="flex flex-col gap-2 lg:items-center basis-2/12">
                                            <span className="text-aeblack-500  text-xs">
                                                {isArabic ? "الحالة" : "Status"}
                                            </span>
                                            <span
                                                className={`${getClassName(item.statusEN)} rounded-2xl text-xs  p-2 text-whitely-50`}
                                            >
                                                {isArabic
                                                    ? item.statusAR
                                                    : item.statusEN}
                                            </span>
                                        </div>

                                        <div className="flex flex-col basis-2/12 lg:self-center lg:items-center">
                                            <a
                                                href="#!"
                                                className="!text-primary-500 hover:!underline mt-4 text-sm"
                                                onClick={() =>
                                                    handleViewClick(item)
                                                }
                                            >
                                                {isArabic
                                                    ? "اظهار المؤهلات"
                                                    : "View Qualifications"}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="font-bold text-2xl text-center">
                                {isArabic ? "لاتوجد بيانات" : "No Data Found"}
                            </div>
                        )}
                    </div>

                    <MOEPagenation
                        perPage={perPage}
                        total={openData.totalFiles}
                        handlePageClick={(e) =>
                            changePage(e.target.textContent)
                        }
                        currentPage={currentPage}
                    />
                </div>
            )}
            <MDBModal
                show={showModal}
                onHide={() => setShowModal(false)}
                className="assessment-Modal"
            >
                <MDBModalDialog centered size="xl">
                    <MDBModalContent>
                        <button
                            type="button"
                            className="btn-close lg:mt-3"
                            color="none"
                            onClick={() => setShowModal(false)}
                        />
                        <MDBModalBody>
                            {loadingTable ? (
                                Array(perPage)
                                    .fill()
                                    .map((item, index) => (
                                        <MDBCol className="mb-4" key={index}>
                                            <Skeleton
                                                key={index}
                                                height={72}
                                                style={{
                                                    width: "100%",
                                                    borderRadius: ".5rem",
                                                    marginTop: "0.5rem",
                                                }}
                                            />
                                        </MDBCol>
                                    ))
                            ) : (
                                <div className="overflow-auto max-h-[70vh]">
                                    <table
                                        className={`table table-bordered align-middle  !border-separate border-spacing-1 ${isArabic ? "text-right" : "text-left"}`}
                                    >
                                        <thead class="table-light">
                                            <tr>
                                                <th className="!bg-aeblack-100 dark:!bg-gray-800 min-w-48 lg:min-fit border-none rounded-ss-lg font-semibold text-sm">
                                                    {isArabic
                                                        ? "الاسم"
                                                        : "Name"}
                                                </th>
                                                <th className="!bg-aeblack-100 dark:!bg-gray-800 min-w-48 lg:min-fit border-none font-semibold text-sm">
                                                    {isArabic
                                                        ? "النوع"
                                                        : "Type"}
                                                </th>
                                                <th className="!bg-aeblack-100 w-fit  dark:!bg-gray-800 border-none font-semibold text-sm">
                                                    {isArabic
                                                        ? "المستوى"
                                                        : "Level"}
                                                </th>

                                                <th className="!bg-aeblack-100 w-fit min-w-48 lg:min-fit dark:!bg-gray-800 border-none font-semibold text-sm">
                                                    {isArabic
                                                        ? "وطني / أجنبي"
                                                        : "National / Foreign"}
                                                </th>

                                                <th className="!bg-aeblack-100 w-fit dark:!bg-gray-800 border-none rounded-se-lg font-semibold text-sm">
                                                    {isArabic
                                                        ? "الحالة"
                                                        : "Status"}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {qualificationData.length > 0 ? (
                                                qualificationData.map(
                                                    (data) => (
                                                        <tr key={data.id}>
                                                            <td className="!bg-aeblack-50 dark:!bg-gray-900 text-sm border-none">
                                                                {isArabic
                                                                    ? data.qualificationTitleAR
                                                                    : data.qualificationTitleEN}
                                                            </td>
                                                            <td className="!bg-aeblack-50 dark:!bg-gray-900 text-sm border-none">
                                                                {isArabic
                                                                    ? data.qualificationTypeAR
                                                                    : data.qualificationTypeEN}
                                                            </td>

                                                            <td className="!bg-aeblack-50 dark:!bg-gray-900  text-sm border-none">
                                                                {isArabic
                                                                    ? data.qfEmirateLevelAR
                                                                    : data.qfEmirateLevelEN}
                                                            </td>
                                                            <td className="!bg-aeblack-50 dark:!bg-gray-900  text-sm border-none">
                                                                {isArabic
                                                                    ? data.typeAR
                                                                    : data.typeEN}
                                                            </td>
                                                            <td className="!bg-aeblack-50 dark:!bg-gray-900 text-sm border-none">
                                                                <span
                                                                    className={`${getClassName(data.statusEN)} rounded-2xl text-xs py-1 px-2 text-whitely-50`}
                                                                >
                                                                    {isArabic
                                                                        ? data.statusAR
                                                                        : data.statusEN}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ),
                                                )
                                            ) : (
                                                <tr className="mt-4">
                                                    <td
                                                        colSpan="5"
                                                        className="text-center"
                                                    >
                                                        {isArabic
                                                            ? "لا توجد مؤهلات"
                                                            : "No Qualifications Found"}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            <MDBModal
                show={fullscreenModal}
                onHide={() => setFullscreenModal(false)}
                className="assessment-Modal"
            >
                <MDBModalDialog size="fullscreen">
                    <MDBModalContent>
                        <div className="flex text-aeblack-900 dark:!text-whitely-50 text-lg mb-3">
                            <i
                                className={` ph ${isArabic ? "ph-caret-right" : "ph-caret-left"}`}
                                onClick={toggleOpen}
                            ></i>
                            <p className="mx-auto text-sm">
                                {isArabic
                                    ? "تصفية حسب الفئات"
                                    : "Filter by categories"}
                            </p>
                        </div>

                        <MDBModalBody>
                            <h6 className="text-xl">
                                {isArabic ? "الإمارة" : "Emirate"}
                            </h6>
                            <div className="flex gap-1 flex-wrap">
                                <span
                                    className={`border border-aeblack-500 text-sm w-fit p-2 rounded-3xl cursor-pointer hover:bg-aeblack-500 ${!selectedEmirateId ? "bg-aeblack-500 text-white" : ""}`}
                                    onClick={() =>
                                        handleEmirateIdChange({
                                            target: { value: "" },
                                        })
                                    }
                                >
                                    {isArabic ? "الكل" : "All"}
                                </span>

                                {emiratesATP
                                    .slice()
                                    .sort((a, b) => {
                                        if (isArabic) {
                                            return a.nameAr.localeCompare(
                                                b.nameAr,
                                                "ar",
                                            );
                                        } else {
                                            return a.nameEn.localeCompare(
                                                b.nameEn,
                                                "en",
                                            );
                                        }
                                    })
                                    .map((item, index) => (
                                        <span
                                            key={index}
                                            className={`border border-aeblack-500 w-fit text-sm  p-2 rounded-3xl cursor-pointer hover:bg-aeblack-500 ${selectedEmirateId === item.id ? "bg-aeblack-500 text-white" : ""}`}
                                            onClick={() =>
                                                handleEmirateIdChange({
                                                    target: { value: item.id },
                                                })
                                            }
                                        >
                                            {isArabic
                                                ? item.nameAr
                                                : item.nameEn}
                                        </span>
                                    ))}
                            </div>

                            <hr className="my-4 text-aeblack-100 opacity-100" />
                            <h6 className="text-xl">
                                {isArabic ? "نوع" : "Type"}
                            </h6>
                            <div className="flex gap-1 flex-wrap">
                                <span
                                    className={`border border-aeblack-500 w-fit text-sm p-2 rounded-3xl cursor-pointer hover:bg-aeblack-500 ${selectedStatus !== 0 && selectedStatus !== 1 ? "bg-aeblack-500 text-white" : ""}`}
                                    onClick={() =>
                                        handleStatusChange({
                                            target: { value: "" },
                                        })
                                    }
                                >
                                    {isArabic ? "الكل" : "All"}
                                </span>

                                {status.map((item, index) => (
                                    <span
                                        key={index}
                                        className={`border border-aeblack-500 w-fit text-sm p-2 rounded-3xl cursor-pointer hover:bg-aeblack-500 ${selectedStatus === item.id ? "bg-aeblack-500 text-white" : ""}`}
                                        onClick={() =>
                                            handleStatusChange({
                                                target: { value: item.id },
                                            })
                                        }
                                    >
                                        {isArabic
                                            ? item.statusAr
                                            : item.statusEn}
                                    </span>
                                ))}
                            </div>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <div className="flex flex-col gap-2 w-full">
                                <button
                                    onClick={() => {
                                        handleApply();
                                        setFullscreenModal(false); //
                                    }}
                                    className="aegov-btn btn-lg "
                                    type="button"
                                >
                                    {isArabic ? "عرض النتائج" : "See Results"}
                                </button>
                                <button
                                    onClick={handleReset}
                                    className="aegov-btn btn-outline  btn-lg"
                                    type="button"
                                >
                                    {isArabic ? "إعادة" : "Reset"}
                                </button>
                            </div>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </MOELayout>
    );
};
export default NQCAccreditedTrainingProvidersPage;
