import React from "react";
import { useSelector } from "react-redux";
import DLSLegislation from "../../components/DLS/DLSLegislation";
import LegislationFilter from "../../components/DLS/DLSLegislation/LegislationFilter";
import LegislationLogo from "../../components/DLS/DLSLegislation/LegislationLogo";

const CabinetResolutions = () => {
    const { InstitutionalPolicies } = useSelector((state) => state.legislation);
    return (
        <div className="flex flex-col gap-10">
            <LegislationFilter />
            <DLSLegislation
                data={InstitutionalPolicies}
                title={isArabic ? "قرارات مجلس الوزراء" : "Cabinet Resolutions"}
            />
            {/* Logo Container */}
            <LegislationLogo />
        </div>
    );
};

export default CabinetResolutions;
