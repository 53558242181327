import React from 'react'

const DLSSearchForm = ({label, placeholder = "", value, onChange, onSubmit, className}) => (
    <form className={`aegov-form-control ${className || ''}`} onSubmit={onSubmit}>
        {label && <label htmlFor={'newSearchTxt'}>{label}</label>}
        <div className="form-control-input">
            <button type="submit" id='searchBtn' className="control-prefix" aria-label='Search'>
                <i className='ph ph-magnifying-glass text-2xl' />
                <span className="sr-only">{isArabic ? 'بحث' : 'Search'}</span>
            </button>

            <input
                id="newSearchTxt"
                className="form-control border-0"
                type="search"
                name="query"
                placeholder={placeholder || (isArabic ? "أدخل الكلمات الرئيسية للبحث" : "Enter keywords to search")}
                value={value}
                onChange={onChange}
                aria-label="Search"
            />
        </div>
    </form>
)
export default DLSSearchForm