import {MDBContainer, MDBRow} from 'mdb-react-ui-kit';
import React from 'react'
import MOELayout from "../../layouts/MOELayout";
import NQSList from '../../components/NQSList';
import NQCPartnerspPage from '../../components/NQSList/partners';

const NQCPartnerPage = () => {
  return (
    <MOELayout pageClassName='service-card-page'>

      <NQCPartnerspPage />
    </MOELayout>
  )
}

export default NQCPartnerPage