/* eslint-disable react/no-danger */
import moment from "moment";
import React from "react";
const PartnershipsDetailsModal = ({ data }) => {
    const {
        Title,
        TitleAR,
        PartnerNameAr,
        PartnerName,
        PartnerNameAR,
        PartnershipType,
        PartnershipDescription_x0020_,
        PartnershipDescriptionAr,
        LocalInternational_x0020_,
        LocalInternationalAR,
        PartnershipStartDate,
        ContactNumber,
        Email,
    } = data;
    // const notAvailable = isArabic ? "غير متوفر" : "N/A";
    const notAvailable = null;

    const itemsList = [
        {
            icon: "ph ph-building-apartment",
            title: isArabic ? "اسم الجامعة" : "University Name",
            value: isArabic ? TitleAR : Title,
        },

        {
            icon: "ph ph-calendar",
            title: isArabic ? "تاريخ البدء و الانتهاء" : "Start & End Date",
            value: `${PartnershipStartDate ? moment(PartnershipStartDate).format("DD/MM/YYYY") : notAvailable} }`,
            direction: "ltr",
        },
        {
            icon: "ph ph-hand-coins",
            title: isArabic ? "اسم الجهة الشريكة" : "Partner Name",
            value: (isArabic ? PartnerNameAR : PartnerName) || notAvailable,
        },
        {
            icon: "ph ph-users-three",
            title: isArabic ? "نوع الشراكة" : "Partnership Type",
            value: PartnershipType || notAvailable,
        },
        {
            icon: "ph ph-building-office",
            title: isArabic ? "جهة محلية / خارجية" : "National / International",
            value:
                (isArabic ? LocalInternationalAR : LocalInternational_x0020_) ||
                notAvailable,
        },
        {
            icon: "ph ph-phone",
            title: isArabic ? "رقم الهاتف" : "Contact",
            value: ContactNumber || notAvailable,
            direction: "ltr",
        },
        {
            icon: "ph ph-envelope",
            title: isArabic ? "البريد الإلكتروني" : "Email",
            value: Email || notAvailable,
        },
    ];

    return (
        <div
            className="modal fade"
            id="partnershipDetails"
            tabIndex="-1"
            aria-labelledby="eventDetailsLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="aegov-modal-wrapper">
                        <button
                            type="button"
                            className="aegov-modal-close top-2 end-2 z-30"
                            data-mdb-ripple-init
                            aria-label="Close"
                            data-mdb-dismiss="modal"
                        >
                            <i className="ph ph-x text-xl" />
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="modal-body">
                            <h5 className="text-xl font-medium mb-4 pe-6">
                                {(isArabic ? PartnerNameAr : PartnerName) ||
                                    notAvailable}
                            </h5>

                            <div className="flow-root">
                                <dl className="-my-3 divide-y divide-gray-100 text-sm">
                                    <div className="flex flex-col gap-1 py-3 items-start even:bg-gray-50 dark:even:bg-gray-900  sm:gap-4">
                                        <dd
                                            className="text-gray-700 dark:text-gray-300 col-span-2"
                                            dangerouslySetInnerHTML={{
                                                __html: isArabic
                                                    ? PartnershipDescriptionAr
                                                    : PartnershipDescription_x0020_,
                                            }}
                                        />
                                    </div>

                                    {itemsList.map(
                                        (item, index) =>
                                            item.value && (
                                                <div
                                                    className="grid grid-cols-3 gap-1 py-3 items-center even:bg-gray-50 dark:even:bg-gray-900  sm:gap-4"
                                                    key={index}
                                                >
                                                    <dt className="font-medium flex items-center text-gray-900">
                                                        <i
                                                            className={`text-xl mx-2 ${item.icon}`}
                                                        />
                                                        {item.title}
                                                    </dt>
                                                    <dd
                                                        className={`text-gray-700 dark:text-gray-300 col-span-2 ${isArabic ? "text-right" : "text-left"}`}
                                                        dir={
                                                            item.direction ||
                                                            (isArabic
                                                                ? "rtl"
                                                                : "ltr")
                                                        }
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.value,
                                                        }}
                                                    />
                                                </div>
                                            ),
                                    )}
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartnershipsDetailsModal;
