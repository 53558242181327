/* eslint-disable node/no-missing-import */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    applyFilters,
    SET_SELECTED_CATEGORY,
    SET_SELECTED_YEAR,
    UPDATE_SEARCH,
} from "../../../redux/actions/legislationActions";
import DLSSearchForm from "../DLSSearchForm";

const LegislationFilter = () => {
    const {
        lawCategories,
        selectedCategory,
        selectedYear,
        search,
        START_YEAR,
        END_YEAR,
        loading,
    } = useSelector((state) => state.legislation);
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    if (loading) {
        return (
            <div className="flex justify-between items-center">
                <Skeleton className="h-[48px] w-[120px] rounded-xl" />
                <Skeleton className="h-[48px] w-[250px] rounded-xl" />
            </div>
        );
    }
    return (
        <div className="filter-container flex sm:flex-row flex-col-reverse justify-between relative gap-4">
            <div className="aegov-form-control">
                <div className="form-control-input">
                    <select
                        id="year"
                        name="year"
                        value={selectedYear}
                        onChange={(e) => {
                            dispatch({
                                type: SET_SELECTED_YEAR,
                                payload: e.target.value,
                            });
                            dispatch(applyFilters());
                        }}
                    >
                        <option value="">
                            {isArabic ? "كل السنوات" : "All Years"}
                        </option>
                        {Array.from(
                            {
                                length: END_YEAR - START_YEAR, // Calculate the number of years between START_YEAR and END_YEAR
                            },
                            (_, i) => END_YEAR - i, // Calculate the year for each option
                        ).map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {/* <button
                id="filter-dropdown-button"
                data-dropdown-toggle="filter-dropdown"
                className="aegov-btn max-sm:self-end btn-outline btn-secondary"
                type="button"
            >
                {isArabic ? "تصفية" : "Filter"}
                <i className="ph ph-faders text-2xl" />
            </button> */}

            <div
                id="filter-dropdown"
                className="aegov-dropdown sm:w-[400px] w-full absolute top-full inset-x-0 !translate-x-0 sm:translate-y-14 translate-y-32 z-10"
            >
                <div className="dropdown-body flex flex-col gap-10 p-8 text-aeblack-600 dark:text-whitely-50">
                    <h4 className="text-lg font-semibold">
                        {isArabic ? "تصفية" : "Filter"}
                    </h4>
                    <div className="flex flex-col gap-6">
                        {/* Category Filter */}
                        <div className="aegov-form-control">
                            <div className="form-control-input">
                                <select
                                    id="category"
                                    name="category"
                                    className="aegov-select"
                                    value={selectedCategory}
                                    onChange={(e) => {
                                        // setDropdownOpen(true);
                                        dispatch({
                                            type: SET_SELECTED_CATEGORY,
                                            payload: e.target.value,
                                        });
                                    }}
                                >
                                    <option value="">
                                        {isArabic
                                            ? "كل التصنيفات"
                                            : "All Categories"}
                                    </option>
                                    {lawCategories.map((category) => (
                                        <option
                                            key={category.Id}
                                            value={category.Id}
                                        >
                                            {
                                                category[
                                                    isArabic
                                                        ? "TitleAr"
                                                        : "Title"
                                                ]
                                            }
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {/* Year Filter */}
                        <div className="aegov-form-control">
                            <div className="form-control-input">
                                <select
                                    id="year"
                                    name="year"
                                    value={selectedYear}
                                    onChange={(e) => {
                                        // setDropdownOpen(true);
                                        dispatch({
                                            type: SET_SELECTED_YEAR,
                                            payload: e.target.value,
                                        });
                                    }}
                                >
                                    <option value="">
                                        {isArabic ? "كل السنوات" : "All Years"}
                                    </option>
                                    {Array.from(
                                        {
                                            length: END_YEAR - START_YEAR, // Calculate the number of years between START_YEAR and END_YEAR
                                        },
                                        (_, i) => END_YEAR - i, // Calculate the year for each option
                                    ).map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <button
                            className="aegov-btn btn-outline btn-secondary flex-1"
                            type="button"
                            // onClick={handleReset}
                        >
                            <i className="ph ph-arrow-counter-clockwise text-xl" />
                            {isArabic ? "مسح" : "Reset"}
                        </button>
                        <button
                            data-dropdown-close="filter-dropdown"
                            className="aegov-btn flex-1"
                            type="button"
                            onClick={() => dispatch(applyFilters())}
                        >
                            {isArabic ? "تطبيق" : "Apply"}
                            <i className="ph ph-check text-xl" />
                        </button>
                    </div>
                </div>
            </div>

            {/* Search Form */}
            <DLSSearchForm
                placeholder={isArabic ? "ابحث" : "Search"}
                value={search}
                onChange={async (e) => {
                    e.preventDefault();
                    await dispatch({
                        type: UPDATE_SEARCH,
                        payload: e.target.value,
                    });
                    await dispatch(applyFilters());
                }}
                onSubmit={(e) => {
                    e.preventDefault();
                    dispatch(applyFilters());
                }}
            />
        </div>
    );
};

export default LegislationFilter;
