import React from 'react';

const DLSPagination = ({currentPage, totalItems, itemsPerPage, onPageChange}) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
        <div className="flex items-center justify-center">
            <div className="aegov-pagination max-sm:hidden" role="navigation">
                {/* Previous button */}
                {
                    totalPages > 1 &&
                    <button
                        type="button"
                        className="aegov-pagination-previous"
                        aria-label="Previous Page"
                        disabled={currentPage === 1}
                        onClick={() => onPageChange(currentPage - 1)}
                    >
                        <svg
                            aria-hidden="true"
                            className="rtl:rotate-180"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 256"
                        >
                            <rect width="256" height="256" fill="none" />
                            <polyline
                                points="160 208 80 128 160 48"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                            />
                        </svg>
                        <span>{isArabic ? 'السابق' : 'Previous'}</span>
                    </button>
                }
                {/* Page numbers */}
                {
                    totalItems > itemsPerPage &&
                    Array.from({length: totalPages}, (_, i) => i + 1).map((item) => (
                        <button
                            key={item}
                            type="button"
                            className={`${currentPage === item
                                ? 'aegov-pagination-current'
                                : 'aegov-pagination-page'
                                }`}
                            title={`Page ${item}`}
                            onClick={() => onPageChange(item)}
                        >
                            {item}
                        </button>
                    ))}

                {/* Next button */}
                {

                    totalPages > 1 &&
                    <button
                        type="button"
                        className="aegov-pagination-next"
                        aria-label="Next Page"
                        disabled={currentPage === totalPages}
                        onClick={() => onPageChange(currentPage + 1)}
                    >
                        <span>{isArabic ? 'التالي' : 'Next'}</span>
                        <svg
                            aria-hidden="true"
                            className="rtl:rotate-180"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 256"
                        >
                            <rect width="256" height="256" fill="none" />
                            <polyline
                                points="96 48 176 128 96 208"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                            />
                        </svg>
                    </button>
                }
            </div>
        </div>
    );
};

export default DLSPagination;
