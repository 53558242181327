export const levels = [
  {id: "2c89e3b5-c603-ea11-8427-0050568a075f", levelAr: "المستوى 1", levelEn: "Level 1"},
  {id: "2e89e3b5-c603-ea11-8427-0050568a075f", levelAr: "المستوى 2", levelEn: "Level 2"},
  {id: "3089e3b5-c603-ea11-8427-0050568a075f", levelAr: "المستوى 3", levelEn: "Level 3"},
  {id: "3289e3b5-c603-ea11-8427-0050568a075f", levelAr: "المستوى 4", levelEn: "Level 4"},
  {id: "3489e3b5-c603-ea11-8427-0050568a075f", levelAr: "المستوى 5", levelEn: "Level 5"},
  {id: "3689e3b5-c603-ea11-8427-0050568a075f", levelAr: "المستوى 6", levelEn: "Level 6"},
  {id: "3889e3b5-c603-ea11-8427-0050568a075f", levelAr: "المستوى 7", levelEn: "Level 7"},
  {id: "3a89e3b5-c603-ea11-8427-0050568a075f", levelAr: "المستوى 8", levelEn: "Level 8"},
  {id: "3c89e3b5-c603-ea11-8427-0050568a075f", levelAr: "المستوى 9", levelEn: "Level 9"}
];

export const levelsApprenticesship = [
  {id: "lvl1", levelAr: "1", levelEn: "Level 1"},
  {id: "Level 2", levelAr: "2", levelEn: "Level 2"},
  {id: "Level 3", levelAr: "3", levelEn: "Level 3"},
  {id: "Level 4", levelAr: "4", levelEn: "Level 4"},
];
export const categories = [
  {categoryAr: "أجنبي", categoryEn: "International", categoryValueEn: "Foreign"}, {categoryAr: "وطني", categoryEn: "National", categoryValueEn: "National"}
]
export const emirates =
  [
    {
      id: "d719a57c-20fa-e911-8429-0050568a17c4",
      nameAr: "أبوظبي",
      nameEn: "Abu Dhabi"
    },
    {
      id: "df19a57c-20fa-e911-8429-0050568a17c4",
      nameAr: "أم القيوين",
      nameEn: "Umm Al Quwain"
    },
    {
      id: "db19a57c-20fa-e911-8429-0050568a17c4",
      nameAr: "الشارقة",
      nameEn: "Sharjah"
    },
    {
      id: "e319a57c-20fa-e911-8429-0050568a17c4",
      nameAr: "الفجيرة",
      nameEn: "Fujairah"
    },
    {
      id: "e519a57c-20fa-e911-8429-0050568a17c4",
      nameAr: "خارج البلد",
      nameEn: "Abroad"
    },
    {
      id: "69613fad-8ee3-eb11-842b-0050568a6e74",
      nameAr: "خارج الدولة",
      nameEn: "Outside UAE"
    },
    {
      id: "d919a57c-20fa-e911-8429-0050568a17c4",
      nameAr: "دبي",
      nameEn: "Dubai"
    },
    {
      id: "e119a57c-20fa-e911-8429-0050568a17c4",
      nameAr: "رأس الخيمة",
      nameEn: "Ras Al Khaimah"
    },
    {
      id: "dd19a57c-20fa-e911-8429-0050568a17c4",
      nameAr: "عجمان",
      nameEn: "Ajman"
    }
  ];

export const emiratesATP = [
  {nameAr: "أبوظبي", nameEn: "Abu Dhabi", id: "d719a57c-20fa-e911-8429-0050568a17c4"},
  {nameAr: "أم القيوين", nameEn: "Umm Al Quwain", id: "df19a57c-20fa-e911-8429-0050568a17c4"},
  {nameAr: "الشارقة", nameEn: "Sharjah", id: "db19a57c-20fa-e911-8429-0050568a17c4"},
  {nameAr: "الفجيرة", nameEn: "Fujairah", id: "e319a57c-20fa-e911-8429-0050568a17c4"},
  {nameAr: "خارج البلد", nameEn: "Abroad", id: "e519a57c-20fa-e911-8429-0050568a17c4"},
  {nameAr: "خارج الدولة", nameEn: "Outside UAE", id: "69613fad-8ee3-eb11-842b-0050568a6e74"},
  {nameAr: "دبي", nameEn: "Dubai", id: "d919a57c-20fa-e911-8429-0050568a17c4"},
  {nameAr: "رأس الخيمة", nameEn: "Ras Al Khaimah", id: "e119a57c-20fa-e911-8429-0050568a17c4"},
  {nameAr: "عجمان", nameEn: "Ajman", id: "dd19a57c-20fa-e911-8429-0050568a17c4"}]

export const emiratesApprenticeship = [

  {id: "497", nameEn: "Abu Dhabi", nameAr: "أبو ظبي"},
  {id: "Abu Dhabi - AL Ain", nameEn: "Abu Dhabi - Al Ain", nameAr: "أبوظبي - العين"},
  {id: "498", nameEn: "Dubai", nameAr: "دبي"},
  {id: "499", nameEn: "Sharjah", nameAr: "الشارقة"},
  {id: "500", nameEn: "Ajman", nameAr: "عجمان"},
  {id: "501", nameEn: "Ras Al Khaimah", nameAr: "رأس الخيمة"},
  {id: "502", nameEn: "Umm Al Quwain", nameAr: "أم القيوين"},
  {id: "503", nameEn: "Fujairah", nameAr: "الفجيرة"},
]
export const CatLegislationsList = [

  {id: 456, categoryAr: "السياسات", categoryEn: "Policies"},
  {id: 457, categoryAr: "الإجراءات", categoryEn: "Procedures"},
  {id: 460, categoryAr: "أدلة", categoryEn: "Guides"},
  {id: 462, categoryAr: "تعاميم", categoryEn: "Circulars"}
];

export const years = [

  {id: 2025, year: "2025"},
  {id: 2024, year: "2024"},
  {id: 2023, year: "2023"},
  {id: 2022, year: "2022"},
  {id: 2021, year: "2021"},
  {id: 2020, year: "2020"},
  {id: 2019, year: "2019"},
  {id: 2018, year: "2018"},
  {id: 2017, year: "2017"},
  {id: 2016, year: "2016"},
  {id: 2015, year: "2015"},
  {id: 2014, year: "2014"},
  {id: 2013, year: "2013"},
  {id: 2012, year: "2012"},
  {id: 2011, year: "2011"},
  {id: 2010, year: "2010"},
  {id: 2009, year: "2009"},
  {id: 2008, year: "2008"},
  {id: 2007, year: "2007"},
  {id: 2006, year: "2006"},
  {id: 2005, year: "2005"},
  {id: 2004, year: "2004"},
  {id: 2003, year: "2003"},
  {id: 2002, year: "2002"},
  {id: 2001, year: "2001"},
  {id: 2000, year: "2000"}
];

export const status = [
  {
    id: 0,
    statusAr: 'نشط',
    statusEn: 'Active'
  },
  {
    id: 1,
    statusAr: 'غير نشط',
    statusEn: 'Inactive'
  }

]

export const statusApprenticeship = [
  {
    id: 0,
    statusAr: 'مفعل',
    statusEn: 'Active'
  },
  {
    id: 1,
    statusAr: 'غير مفعل',
    statusEn: 'Inactive'
  }

]
export const institutes = [

  {
    id: "ATP2",
    nameAr: "معهد أبوظبي للتعليم والتدريب المهني",
    nameEn: "Abu Dhabi Vocational Education and Training Institute"
  },
  {
    id: "Dubai College of Tourism",
    nameAr: "كلية دبي للسياحة",
    nameEn: "Dubai College of Tourism"
  }
];


export const partnersCategories = [
  {"nameEn": "Local Partners", "nameAr": "شركاء محليين"},
  {"nameEn": "International Partners", "nameAr": "الشركاء الدوليين"}
]
export const partnersSectors =
  [
    {"nameAr": "جهة حكومية اتحادية", "nameEn": "Federal Government Entity"},
    {"nameAr": "جهة حكومية محلية", "nameEn": "Local Government Entity"},
    {"nameAr": "جهة شبه حكومية", "nameEn": "Semi-government Government Entity"},
    {"nameAr": "قطاع خاص", "nameEn": "Private Sector"},
    {"nameAr": "جهة حكومية", "nameEn": "Government Entity"}
  ]




export const employers = [
  {id: "Al Ain CO-OP", nameEn: "Al Ain CO-OP", nameAr: "تعاونية العين"},
  {id: "Al Hamah Waste Water Treatment Plan", nameEn: "Al Hamah Waste Water Treatment Plan", nameAr: "الحمة لخطة معالجة مياه الصرف الصحي"},
  {id: "Altrad", nameEn: "Altrad", nameAr: "التراد"},
  {id: "Bareen Hospital", nameEn: "Bareen Hospital", nameAr: "مستشفى بارين"},
  {id: "Dar Al-Handasah Consultants", nameEn: "Dar Al-Handasah Consultants", nameAr: "دار الهندسة للاستشارات"},
  {id: "Doubletree by Hilton, Business Bay", nameEn: "Doubletree by Hilton, Business Bay", nameAr: "فندق دبل تري باي هيلتون دبي"},
  {id: "Emirates Prefabrication Industries L.L.C (EPIC)", nameEn: "Emirates Prefabrication Industries L.L.C (EPIC)", nameAr: "الإمارات للصناعات الجاهزة"},
  {id: "Emirates Steel Arkan", nameEn: "Emirates Steel Arkan", nameAr: "حديد الإمارات أركان"},
  {id: "ENPI GROUP •  Emirates National Factory for Plastic Industries", nameEn: "ENPI GROUP • Emirates National Factory for Plastic Industries", nameAr: "مجموعة ENPI • مصنع الإمارات الوطني للصناعات البلاستيكية"},
  {id: "Galfar Engineering", nameEn: "Galfar Engineering", nameAr: "جلفار للهندسة والمقاولات"},
  {id: "Gates Hospitality", nameEn: "Gates Hospitality", nameAr: "جيتس هوسبيتاليتي"},
  {id: "Gulf Industrial Services Company L.L.C (GISCO)", nameEn: "Gulf Industrial Services Company L.L.C (GISCO)", nameAr: "شركة الخليج للخدمات الصناعية – جيسكو ذ.م.م"},
  {id: "Gulf Precast Concrete Company ", nameEn: "Gulf Precast Concrete Company", nameAr: "شركة الخليج للخرسانة الجاهزة"},
  {id: "Hilton Dubai Jumeirah/Hilton Dubai The Walk", nameEn: "Hilton Dubai Jumeirah/Hilton Dubai The Walk", nameAr: "هيلتون دبي الممشى"},
  {id: "JW Marriott", nameEn: "JW Marriott", nameAr: "جي دبليو ماريوت"},
  {id: "Nakheel Hygienic Paper Manufacturing", nameEn: "Nakheel Hygienic Paper Manufacturing", nameAr: "النخيل لصناعة الورق الصحي"},
  {id: "Neopharma", nameEn: "Neopharma", nameAr: "نيــوفارما"},
  {id: "Novomed", nameEn: "Novomed", nameAr: "نوفومد"},
  {id: "Orascom Construction", nameEn: "Orascom Construction", nameAr: "أوراسكوم للإنشاءات"},
  {id: "P One Real Estate and General Maintenance ", nameEn: "P One Real Estate and General Maintenance", nameAr: "بي ون لادارة العقارات والصيانة العامة"},
  {id: "Saber", nameEn: "Saber", nameAr: "مجموعة صابر الاستثمارية"},
  {id: "SAIPEM", nameEn: "SAIPEM", nameAr: "سايبم"},
  {id: "Shaw Emirates Pipes Manufacturing LLC", nameEn: "Shaw Emirates Pipes Manufacturing LLC", nameAr: "شو الإمارات لصناعة الأنابيب ذ.م.م (مجموعة شو)"},
  {id: "The Westin Dubai Mina Seyahi Beach Resort & Marina", nameEn: "The Westin Dubai Mina Seyahi Beach Resort & Marina", nameAr: "منتجع ومارينا ذا وستن دبي الميناء السياحي"},
  {id: "Victoria's Secret", nameEn: "Victoria's Secret", nameAr: "فيكتوريا سيكريت"},
  {id: "Al Masraf Bank", nameEn: "Al Masraf Bank", nameAr: "المصرف"},
  {id: "Apparel Group", nameEn: "Apparel Group", nameAr: "مجموعة أباريل"},
  {id: "BinSina Pharmacy", nameEn: "BinSina Pharmacy", nameAr: "صيدلية ابن سينا"},
  {id: "Carrefour", nameEn: "Carrefour", nameAr: "كارفور"},
  {id: "Faces", nameEn: "Faces", nameAr: "وجوه"},
  {id: "Home Centre", nameEn: "Home Centre", nameAr: "هوم سنتر"},
  {id: "Lulu", nameEn: "Lulu", nameAr: "اللولو"},
  {id: "Polo", nameEn: "Polo", nameAr: "بولو"},
  {id: "Watson's Pharmacy", nameEn: "Watson's Pharmacy", nameAr: "صيدلية واتسونز"},
  {id: "WFC Holding", nameEn: "WFC Holding", nameAr: "دبليو اف سي القابضة"},
  {id: "Zakher Marine International Holdings ", nameEn: "Zakher Marine International Holdings", nameAr: "زاخر مارين العالمية القابضة"},
  {id: "Al Futtaim", nameEn: "Al Futtaim", nameAr: "الفطيم"},
  {id: "Garagash", nameEn: "Garagash", nameAr: "قرقاش"}
];

export const sectors = [
  {id: "3293e095-7338-4455-ae89-9c0dd731a933", sectorEn: "Higher Education", sectorAr: "تعليم عالى"},
  {id: "fec8df5c-d11d-4087-aa8c-f24b3cb5de3d", sectorEn: "Vocational Education", sectorAr: "تعليم مهني"}
];
export const statuses = [
  {id: "e7f54df9-639f-4f6c-b338-2e5cfa582cb4", statusEn: "Active", statusAr: "نشط"},
  {id: "3d65a1aa-0cd7-48cf-9417-c5ee0ccb51d4", statusEn: "Inactive", statusAr: "غير نشط"}
];
export const emiratesCBMC = [
  {id: "497", emirateEn: "Abu Dhabi", emirateAr: "أبو ظبي"},
  {id: "Abu Dhabi - AL Ain", emirateEn: "Abu Dhabi - Al Ain", emirateAr: "أبوظبي - العين"},
  {id: "500", emirateEn: "Ajman", emirateAr: "عجمان"},
  {id: "498", emirateEn: "Dubai", emirateAr: "دبي"},
  {id: "503", emirateEn: "Fujairah", emirateAr: "الفجيرة"},
  {id: "501", emirateEn: "Ras Al Khaimah", emirateAr: "رأس الخيمة"},
  {id: "499", emirateEn: "Sharjah", emirateAr: "الشارقة"},
  {id: "502", emirateEn: "Umm Al Quwain", emirateAr: "أم القيوين"}
];
export const LevelsCBMC = [
  {id: "Level 01", levelEn: "Level 01", levelAr: "المستوى 01"},
  {id: "Level 02", levelEn: "Level 02", levelAr: "المستوى 02"},
  {id: "Level 03", levelEn: "Level 03", levelAr: "المستوى 03"},
  {id: "Level 04", levelEn: "Level 04", levelAr: "المستوى 04"},
  {id: "Level 05", levelEn: "Level 05", levelAr: "المستوى 05"},
  {id: "Level 06", levelEn: "Level 06", levelAr: "المستوى 06"},
  {id: "Level 07", levelEn: "Level 07", levelAr: "المستوى 07"},
  {id: "Level 08", levelEn: "Level 08", levelAr: "المستوى 08"}
];
export const categoriesLegislations = [
  {value: 454, label: "Policies"},
  {value: 455, label: "Procedures"},
  {value: 459, label: "Guides"},
  {value: 461, label: "Circulars"}
];

export const programes = [
  {id: "HOS04002RQ", programEn: "Level 3 Certificate in Culinary Operations", programAr: "شهادة المستوى 3 في عمليات الطهي"},
  {id: "ENG04018NQ22", programEn: "Level 3 Certificate in Light Vehicle Service", programAr: "شهادة المستوى 3 في خدمة المركبات الخفيفة"},
  {id: "Level 4 Diploma for Electrical Technology ", programEn: "Level 4 Diploma for Electrical Technology", programAr: "دبلوم المستوى 4 في التكنولوجيا الكهربائية"},
  {id: "Level 4 Diploma for Mechanical Technology ", programEn: "Level 4 Diploma for Mechanical Technology", programAr: "دبلوم المستوى 4 في التكنولوجيا الميكانيكية"},
  {id: "Level 4 Diploma in Electronics Technology", programEn: "Level 4 Diploma in Electronics Technology", programAr: "دبلوم المستوى 4 في التكنولوجيا الإلكترونية"},
  {id: "Level 4 Diploma in Environment, Health and Safety", programEn: "Level 4 Diploma in Environment, Health and Safety", programAr: "دبلوم المستوى 4 في الصحة والسلامة البيئية"},
  {id: "Level 4 Diploma in Logistics and Supply Chain Operations", programEn: "Level 4 Diploma in Logistics and Supply Chain Operations", programAr: "دبلوم المستوى 4 في الامداد وعمليات سلسلة التوريد"},
  {id: "Level 4 Diploma in Mechatronics Engineering Technology", programEn: "Level 4 Diploma in Mechatronics Engineering Technology", programAr: "دبلوم المستوى 4 في تكنولوجيا هندسة الميكاترونيكس"},
  {id: "RET05001NQ17", programEn: "Level 4 Diploma in Retail Operations", programAr: "دبلوم المستوى 4 في عمليات تجارة التجزئة"},

]

export const statusQualifications = [
  {
    id: 0,
    statusAr: 'نشط',
    statusEn: 'Active'
  },
  {
    id: 1,
    statusAr: 'منتهي الصلاحية',
    statusEn: 'Expired'
  }

]
