import React from 'react';

const SearchInput = ({
  placeholder,
  searchValue,
  handleSearchChange,
  handleSuggestionClick,
  showSuggestions,
  setShowSuggestions,
  filteredSuggestions,
  isArabic
}) => {
  return (
    <div className="aegov-form-control relative">
      <div className="form-control-input max-w-[300px]">
        <input
          type="search"
          name="search_suffix"
          autoComplete="off"
          id="search_suffix"
          placeholder={placeholder}
          value={searchValue}
          onChange={handleSearchChange}
          onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
          onFocus={() => setShowSuggestions(filteredSuggestions.length > 0)}
        />
        <span className="control-suffix relative left-2 top-auto">
          <i className="ph ph-magnifying-glass text-xl"></i>
        </span>
      </div>
      {showSuggestions && searchValue && (
        <ul className="absolute left-0 mt-1 w-full border bg-white shadow-md max-h-40 overflow-auto z-10">
          {filteredSuggestions.length > 0 ? (
            filteredSuggestions.map((suggestion, index) => (
              <li
                key={index}
                className="p-2 cursor-pointer hover:bg-gray-200 whitespace-nowrap"
                onMouseDown={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </li>
            ))
          ) : (
            <li className="p-2 text-gray-500">
              {isArabic ? 'لا توجد نتائج' : 'No results found'}
            </li>
          )}
        </ul>
      )}
    </div>
  );
};


export default SearchInput;
