import React from "react";
import MOELayout from '../../layouts/MOELayout';
import {siteURL} from "../../constants/constants";
import {MDBTooltip} from "mdb-react-ui-kit";
import NQC from '@images/NQC.png';
import NQCDark from '@images/NQC-Dark.png';
const NQCAboutPage = () => {

  const dataPage = {
    NQCObjetives: [
      {
        titleEn: 'Establishment and Execution of the national qualification framework and its levels',
        titleAr: 'وضع و تفعيل المنظومة الوطنية للمؤهلات و مستوياتها'
      },
      {
        titleEn: 'Develop the UAE Technical and Vocational Educations policies and standards as per the best practices.',
        titleAr: 'تطوير السياسات و المعايير المتعلقة بالتعليم والتدريب التقني والمهني في الدولة وفقا لأفضل الممارسات'
      },
      {
        titleEn: 'Standardize the qualifications and the National occupational standards required for UAE jobs.',
        titleAr: 'توحيد معايير المؤهلات و المعايير المطلوبة للمهن والوظائف في الدولة'
      },
      {
        titleEn: 'Develop policies that support life-long learning.',
        titleAr: 'تطوير السياسات التي تدعم مبدا التعلم مدى الحياة'
      },
      {
        titleEn: 'Encourage Learners to enroll in technical and vocational education and training disciplines that meet the needs of economic sectors and the labor market requirements.',
        titleAr: 'تشجيع المتعلمين للالتحاق في مسار التعليم و التدريب التقني و المهني التي تلبي حاجة القطاعات الاقتصادية ومتطلبات سوق العمل'
      },

    ],

    NQCKeyTasks: [
      {
        titleEn: 'Quality assures the Accredited Training Providers (ATP) and vocational qualifications.',
        titleAr: 'ضمان جودة المؤسسات التعليمية و التدريبية و المؤهلات المهنية'
      },
      {
        titleEn: 'Accreditation of the awarding bodies as per the NQC’s standards.',
        titleAr: 'اعتماد الجهات المانحه للمؤهلات المهنية وفقا لضوابط المركز الوطني للمؤهلات'
      },
      {
        titleEn: 'Accreditation of the Accredited Training Providers (ATP) to deliver vocational qualifications.',
        titleAr: 'اعتماد المؤسسات التعليمية و التدريبية لطرح مؤهلات مهنية'
      },
      {
        titleEn: 'Recognize vocational programs and qualifications offered by Accredited Training Providers (ATP)',
        titleAr: 'الاعتراف بالمؤهلات و البرامج المهنية التي تطرحها المؤسسات التعليمية و التدريبية'
      },
      {
        titleEn: (
          <> Attestation of the vocational qualifications developed through economic sectors .
            <MDBTooltip tag='a' placement="top" title="Recognized National Development Committee">
              (<span className="underline">RNDC</span>)
            </MDBTooltip>
          </>
        ),
        titleAr: 'المصادقة على المؤهلات المهنية التي تم تطويرها من خلال القطاعات الاقتصادية'
      },
      {
        titleEn: 'Attestation of the national occupational standards developed by professional regulatory organizations.',
        titleAr: 'المصادقة على المعايير المهنية التي تم تطويرها من خلال الجهات التنظيمية المهنية'
      },
    ],
    descriptonEn: (
      <>
        The National Qualifications Centre was established after the merger of the National Qualifications Authority with the Ministry of Education in 2021.
        <br />The National Qualifications Centre is responsible for ensuring the quality of national occupational standards and vocational qualifications that meet the requirements of the economic sectors and the labor market, in addition to developing policies, standards and frameworks related to the{' '}

        <MDBTooltip tag='a' placement="top" title="Technical Vocational Education &Training">
          <span className="underline">TVET </span>
        </MDBTooltip>
        {' '}
        system in the country in accordance with international best practices.
        <br />The centre cooperates with local and international bodies for the purposes of recognition of qualifications.
      </>
    ),
    descriptonAr: (
      <>
        تأسس المركز الوطني للمؤهلات بعد دمج الهيئة الوطنية للمؤهلات مع وزارة التربية و التعليم في 2021.

        <br />يتولى المركز الوطني للمؤهلات مسؤولية ضمان جودة المعايير والمؤهلات المهنية الوطنية التي تلبي متطلبات القطاعات الاقتصادية و سوق العمل بالإضافة الى تطوير السياسات والمعايير والاطر المتعلقة بمنظومة التعليم والتدريب التقني والمهني في الدولة وفقا لأفضل الممارسات العالمية.
        <br />وللمركز تعاون مع هيئات محلية و دولية لأغراض الاعتراف بالمؤهلات.
      </>
    )
  }
  return (
    <MOELayout>
      <div className="flex flex-col">
        <img className='mb-[56px] hidden lg:block lg:dark:hidden ' src={NQC} alt='NQC' width={274} height={90} />
        <img className='mb-[56px] hidden lg:dark:block ' src={NQCDark} alt='NQC' width={274} height={90} />


        <p className="lg:w-3/4 w-full !mb-[32px] text-xl !leading-[30px]">
          {isArabic ? dataPage.descriptonAr : dataPage.descriptonEn} </p>

        <a
          className="aegov-btn btn-outline w-fit !mb-[48px] lg:!mb-24 "
          type="button"

          href={`${siteURL}${isArabic ? "/Ar" : "/En"}/EServices/Pages/ServiceCatalog.aspx?categoryID=${isArabic ? 9 : 3}`}

        >
          {isArabic ? 'عرض الخدمات' : 'View Services'}
          <i className={`ph text-xl ${isArabic ? 'ph-arrow-left' : 'ph-arrow-right'}`}></i>
        </a>

        <h5 className="text-h5 !mb-3 font-bold">{isArabic ? 'اهداف المركز:' : 'NQC Objectives:'}</h5>
        <ul style={{listStyle: 'auto', gap: '0px'}}>
          {dataPage.NQCObjetives.map((item, index) => (
            <li className="leading-[25px]" key={item.titleEn + '_' + index}>

              {isArabic ? item.titleAr : item.titleEn}

            </li>
          ))
          }
        </ul>
        <h5 className="text-h5 !mb-3 font-bold !mt-[48px] lg:!mt-24">{isArabic ? 'المهام الرئيسية:' : 'Key Tasks:'}</h5>
        <ul style={{listStyle: 'auto', gap: '0px'}}>
          {dataPage.NQCKeyTasks.map((item, index) => (
            <li className="leading-[25px]" key={item.titleEn + '_' + index}>

              {isArabic ? item.titleAr : item.titleEn}

            </li>
          ))
          }
        </ul>
      </div>
      <a
        className="aegov-btn btn-outline w-fit  !mt-[32px]  lg:!mb-24 "
        type="button"

        href={`${siteURL}${isArabic ? "/Ar" : "/En"}/NQC/Pages/partners.aspx`}

      >
        {isArabic ? 'عرض الشركاء' : 'View Partners'}
        <i className={`ph text-xl ${isArabic ? 'ph-arrow-left' : 'ph-arrow-right'}`}></i>
      </a>
    </MOELayout >
  );
};

export default NQCAboutPage;
