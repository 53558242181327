import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalBody,
    MDBModalFooter,
    MDBCol,
} from "mdb-react-ui-kit";
import React, { useEffect, useState, useRef } from "react";
import MOELayout from "../../layouts/MOELayout";
import MOEPagenation from "@components/MOEPagenation";
import { getServicesTrainingِ } from "../../api/trainingِAndEducational.api";
import SearchInput from "./SearchInputAuto";
const TrainingCentersList = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const [openData, setOpenData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [emirates, setEmirates] = useState([]);
    const [fullscreenModal, setFullscreenModal] = useState(false);
    const [selectedEmirate, setselectedEmirate] = useState(null);
    const [allData, setAllData] = useState(null);
    const [loading, setLoading] = React.useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [isMobileView, setIsMobileView] = useState(false);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const debounceTimeout = useRef(null);
    const displayResult = (value) => {
        setSearchValue(value);
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            setCurrentPage(1);
            filterData(value);
        }, 100);
    };
    const handleSearchChange = (event) => {
        const value = event.target.value;
        if (value.trim() === "") {
            setFilteredSuggestions([]);
            setShowSuggestions(false);
        } else {
            setShowSuggestions(true);
        }
        displayResult(value);
    };
    const handleSuggestionClick = (suggestion) => {
        setSearchValue(suggestion);
        setFilteredSuggestions([]);
        setShowSuggestions(false);
        displayResult(suggestion);
    };
    const handleEmirateChange = (event) => {
        setselectedEmirate(event.target.value);
    };
    const dropdownRef = useRef(null);
    const handleDocumentClick = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };
    if (typeof window !== "undefined") {
        document.onclick = handleDocumentClick;
    }
    useEffect(() => {
        const fetchApprenticeshipData = async () => {
            try {
                const response = await getServicesTrainingِ();

                setAllData(response.data.value);

                console.log(response.data.value);
                const EmirateData = Array.from(
                    new Set(
                        response?.data.value.map((item) =>
                            JSON.stringify({ name: item.Emirate }),
                        ),
                    ),
                ).map((str) => JSON.parse(str));
                setEmirates(EmirateData);
                setOpenData({
                    totalFiles: response?.data.value.length,
                    data: response?.data.value,
                });
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchApprenticeshipData();
    }, []);
    const filterData = (value) => {
        let filteredData = allData;
        if (selectedEmirate) {
            filteredData = filteredData.filter(
                (item) => item.Emirate === selectedEmirate,
            );
        }
        if (value) {
            filteredData = filteredData.filter((item) =>
                item.EstablishmentName?.toLowerCase().includes(
                    value.toLowerCase(),
                ),
            );
            const filtered = filteredData
                .filter((val) =>
                    val.EstablishmentName.toLowerCase().includes(
                        value.toLowerCase(),
                    ),
                )
                .map((val) => val.EstablishmentName);
            setFilteredSuggestions(filtered);
        }

        setOpenData({
            totalFiles: filteredData.length,
            data: filteredData,
        });
    };
    const handleApply = () => {
        setFullscreenModal(false);
        setIsOpen(false);
        filterData(searchValue);
        changePage(1);
    };
    // UPDATE MOBILE VIEW ON RESIZE
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const handleReset = () => {
        setselectedEmirate("");
        setSearchValue("");
        setIsOpen(false);
        setFullscreenModal(false);
        setOpenData({
            totalFiles: allData.length,
            data: allData,
        });
        changePage(1);
    };
    const toggleOpen = () => setFullscreenModal(!fullscreenModal);
    const changePage = (textContent) => {
        setCurrentPage(parseInt(textContent));
        $("#s4-workspace").animate({ scrollTop: 0 }, "slow");
    };
    return (
        <MOELayout>
            {loading ? (
                Array(perPage)
                    .fill()
                    .map((item, index) => (
                        <MDBCol className="mb-4" key={index}>
                            <Skeleton
                                key={index}
                                height={72}
                                style={{
                                    width: "100%",
                                    borderRadius: ".5rem",
                                    marginTop: "1.5rem",
                                }}
                            />
                        </MDBCol>
                    ))
            ) : (
                <div className="open-data flex flex-col gap-8 lg:gap-12 pb-12">
                    <div className="flex gap-2 justify-between">
                        <button
                            className=" basis-1/4 bg-transparent p-0 gap-2 flex lg:hidden"
                            onClick={toggleOpen}
                            type="button"
                        >
                            <span>{isArabic ? "بحث" : "Filter"} </span>
                            <i class="ph ph-sliders"></i>
                        </button>

                        <div className="relative" ref={dropdownRef}>
                            <button
                                className="aegov-btn   w-max aegov-btn-filter hidden lg:flex"
                                type="button"
                                onClick={toggleDropdown}
                            >
                                <span>{isArabic ? "بحث" : "Filter"} </span>
                                <i className="ph ph-sliders"></i>
                            </button>

                            {isOpen && (
                                <div
                                    className={`aegov-dropdown absolute rounded-2xl ${isArabic ? "right-0" : "left-0"} ${
                                        isOpen ? "block" : "hidden"
                                    }`}
                                >
                                    <div className="lg:w-80 p-6 border border-primary-400 rounded-2xl space-y-8 bg-whitely-50">
                                        <section className="space-y-4">
                                            <h6>
                                                {isArabic ? "بحث" : "Filter"}{" "}
                                            </h6>
                                            <div className="space-y-3 max-h-[32rem] overflow-auto">
                                                <div className="flex flex-col gap-4">
                                                    <div className="aegov-form-control">
                                                        <div className="form-control-input">
                                                            <select
                                                                value={
                                                                    selectedEmirate ||
                                                                    ""
                                                                }
                                                                onChange={
                                                                    handleEmirateChange
                                                                }
                                                            >
                                                                <option
                                                                    className="dark:!bg-gray-800"
                                                                    value=""
                                                                >
                                                                    {isArabic
                                                                        ? "اختر الإمارة"
                                                                        : "Select Emirate"}
                                                                </option>
                                                                {emirates.map(
                                                                    (
                                                                        item,
                                                                        index,
                                                                    ) => (
                                                                        <option
                                                                            className="dark:!bg-gray-800"
                                                                            value={
                                                                                item.name
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </option>
                                                                    ),
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between gap-2">
                                                        <button
                                                            onClick={
                                                                handleReset
                                                            }
                                                            className="aegov-btn btn-outline  !text-aeblack-800 !border-aeblack-800 btn-sm flex-1"
                                                            type="button"
                                                        >
                                                            <i className="ph ph-arrow-counter-clockwise"></i>
                                                            {isArabic
                                                                ? "إعادة"
                                                                : "Reset"}
                                                        </button>
                                                        <button
                                                            onClick={
                                                                handleApply
                                                            }
                                                            className="aegov-btn btn-sm flex-1"
                                                            type="button"
                                                            disabled={
                                                                !selectedEmirate
                                                            }
                                                        >
                                                            {isArabic
                                                                ? "تطبيق"
                                                                : "Apply"}

                                                            <i className="ph ph-check"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            )}
                        </div>
                        <SearchInput
                            placeholder={
                                isArabic
                                    ? "البحث في المراكز التدريبية"
                                    : "Search in Training Centers"
                            }
                            searchValue={searchValue}
                            handleSearchChange={handleSearchChange}
                            handleSuggestionClick={handleSuggestionClick}
                            showSuggestions={showSuggestions}
                            filteredSuggestions={filteredSuggestions}
                            setShowSuggestions={setShowSuggestions}
                            isArabic={isArabic}
                        />
                    </div>

                    <div className="grid grid-cols-1 gap-[16px]">
                        {openData.data.length > 0 ? (
                            openData.data
                                .slice(
                                    (currentPage - 1) * perPage,
                                    currentPage * perPage,
                                )

                                .map((item, index) => (
                                    <div
                                        className="aegov-card card-bordered !border-transparent
                hover:!border
                 hover:!border-aegold-200 hover:bg-primary-50 hover:dark:bg-transparent hover:shadow-[0px_7px_14.9px_2px_#E4D5B6]"
                                        key={index}
                                    >
                                        <div className="flex flex-col gap-[16px]  lg:gap-8 lg:flex-row justify-content-between items-start ">
                                            <div className="flex flex-col gap-2 basis-3/12">
                                                <span className="text-aeblack-500  text-xs">
                                                    {isArabic
                                                        ? "اسم المنشأة"
                                                        : "Establishment Name"}
                                                </span>
                                                <span className="text-sm font-semibold">
                                                    {item.EstablishmentName}
                                                </span>
                                            </div>
                                            <div className="flex flex-col gap-2 basis-2/12 ">
                                                <span className="text-aeblack-500  text-xs">
                                                    {isArabic
                                                        ? "الإمارة"
                                                        : "Emirate"}
                                                </span>
                                                <span className="text-sm font-semibold">
                                                    {item?.Emirate}
                                                </span>
                                            </div>

                                            <div className="flex flex-col gap-2 basis-2/12 ">
                                                <span className="text-aeblack-500  text-xs">
                                                    {isArabic
                                                        ? "رقم الرخصة"
                                                        : "License Number"}
                                                </span>
                                                <span className="text-sm font-semibold">
                                                    {item?.LicenseNumber}
                                                </span>
                                            </div>

                                            <div className="flex flex-col gap-2 basis-1/12 ">
                                                <span className="text-aeblack-500  text-xs">
                                                    {isArabic
                                                        ? "حالة الرخصة"
                                                        : "License Status"}
                                                </span>
                                                <span className="text-sm font-semibold">
                                                    {item?.LicenseStatus}
                                                </span>
                                            </div>
                                            <div className="flex flex-col gap-2 basis-4/12 ">
                                                <span className="text-aeblack-500  text-xs">
                                                    {isArabic
                                                        ? "الأنشطة"
                                                        : "Activities"}
                                                </span>
                                                <span className="text-sm font-semibold whitespace-pre-line">
                                                    {
                                                        item?.EstablishmentActivities
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <div className="font-bold text-2xl text-center">
                                {isArabic ? "لاتوجد بيانات" : "No Data Found"}
                            </div>
                        )}
                    </div>

                    <MOEPagenation
                        perPage={perPage}
                        total={openData.totalFiles}
                        handlePageClick={(e) =>
                            changePage(e.target.textContent)
                        }
                        currentPage={currentPage}
                    />
                </div>
            )}
            <MDBModal
                show={fullscreenModal}
                onHide={() => setFullscreenModal(false)}
                className="assessment-Modal"
            >
                <MDBModalDialog size="fullscreen">
                    <MDBModalContent>
                        <div className="flex text-aeblack-900  dark:!text-whitely-50 text-lg mb-3">
                            <i
                                className={`ph ${isArabic ? "ph-caret-right" : "ph-caret-left"}`}
                                onClick={toggleOpen}
                            ></i>
                            <p className="mx-auto text-sm">
                                {isArabic
                                    ? "تصفية حسب الفئات"
                                    : "Filter by categories"}
                            </p>
                        </div>

                        <MDBModalBody>
                            {emirates.length > 0 && (
                                <div>
                                    <h6 className="text-xl">
                                        {isArabic ? "الإمارة" : "Emirate"}
                                    </h6>
                                    <div className="flex gap-1 flex-wrap">
                                        <span
                                            className={`border border-aeblack-500 w-fit p-2 text-sm rounded-3xl cursor-pointer hover:bg-aeblack-500 ${!selectedEmirate ? "bg-aeblack-500 text-white" : ""}`}
                                            onClick={() =>
                                                handleEmirateChange({
                                                    target: { value: "" },
                                                })
                                            }
                                        >
                                            {isArabic ? "الكل" : "All"}
                                        </span>
                                        {emirates.map((item, index) => (
                                            <span
                                                key={index}
                                                className={`border border-aeblack-500 w-fit p-2 text-sm rounded-3xl cursor-pointer hover:bg-aeblack-500 ${selectedEmirate === item.name ? "bg-aeblack-500 text-white" : ""}`}
                                                onClick={() =>
                                                    handleEmirateChange({
                                                        target: {
                                                            value: item.name,
                                                        },
                                                    })
                                                }
                                            >
                                                {item.name}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <hr className="my-4 text-aeblack-100 opacity-100" />
                        </MDBModalBody>

                        <MDBModalFooter>
                            <div className="flex flex-col gap-2 w-full">
                                <button
                                    onClick={() => {
                                        handleApply();
                                        setFullscreenModal(false);
                                    }}
                                    className="aegov-btn btn-lg"
                                    type="button"
                                >
                                    {isArabic ? "عرض النتائج" : "See Results"}
                                </button>
                                <button
                                    onClick={handleReset}
                                    className="aegov-btn btn-outline  btn-lg"
                                    type="button"
                                >
                                    {isArabic ? "إعادة" : "Reset"}
                                </button>
                            </div>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </MOELayout>
    );
};
export default TrainingCentersList;
