/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-unused-modules */
/* eslint-disable no-shadow */
import React from "react";
import { useSelector } from "react-redux";
import LegislationFilter from "../../components/DLS/DLSLegislation/LegislationFilter";
import DLSLegislation from "../../components/DLS/DLSLegislation";
import LegislationLogo from "../../components/DLS/DLSLegislation/LegislationLogo";
// eslint-disable-next-line import/no-unused-modules
const MinisterialDecrees = () => {
    const { MinisterialDecrees } = useSelector((state) => state.legislation);
    return (
        <div className="flex flex-col gap-10">
            <LegislationFilter />
            <DLSLegislation
                data={MinisterialDecrees}
                title={isArabic ? "القرارات الوزارية" : "Ministerial Decrees"}
            />
            {/* Logo Container */}
            <LegislationLogo />
        </div>
    );
};

export default MinisterialDecrees;
