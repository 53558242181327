import axios from 'axios';

export const SET_FAQS = 'SET_FAQS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
export const START_FAQ_LOADING = 'START_FAQ_LOADING';
export const SET_ACTIVE_CATEGORY_LOADING = 'SET_ACTIVE_CATEGORY_LOADING';
export const SEARCH_FAQ = 'SEARCH_FAQ';

const CategoriesAPI = `${siteURL}_api/lists/getbytitle%28%27FAQCategories%27%29/items?%24orderby=sortByID%20asc`;
const FAQAPIList = `${siteURL}/_api/lists/getbytitle('faqs')/items?$orderby=OrderEN%20asc&$top=500`;

export const handelFetchFaqs = () => async (dispatch) => {
    dispatch({type: START_FAQ_LOADING});
    try {
        const {data} = await axios.get(FAQAPIList);
        dispatch({type: SET_FAQS, payload: data.value});
    } catch (error) {
        console.log('error in handelFetchFaqs', error);
    }
}

export const handelFetchFaqsCategories = () => async (dispatch) => {
    dispatch({type: SET_ACTIVE_CATEGORY_LOADING});
    try {
        const {data} = await axios.get(CategoriesAPI);

        // Get All categories from the API That has Status = yes

        const categoriesList = data.value.filter((item) => item.status === 'yes')

        // Add active property to the first item in the array
        let result = categoriesList.map((item, index) => ({
            ...item,
            active: index === 0
        }))
        // Dispatch the result to the reducer
        dispatch({type: SET_CATEGORIES, payload: result});

    } catch (error) {
        console.log('error in handelFetchFaqsCategories', error);
    }
}

export const handelActiveCategory = (id) => (dispatch) => {
    dispatch({type: SET_ACTIVE_CATEGORY_LOADING});
    dispatch({type: START_FAQ_LOADING});
    try {
        dispatch({type: SET_ACTIVE_CATEGORY, payload: id});
    } catch (error) {
        console.log('error in handelActiveCategory', error);

    }
}

export const handelSearchFAQ = (search) => (dispatch) => {
    dispatch({type: SEARCH_FAQ, payload: search});
}