import axios from 'axios';
import {siteURL} from '../constants/constants';


const DEFAULT_BASE_URL = siteURL;
const createAxiosInstance = (baseURL = DEFAULT_BASE_URL) => {
  const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.baseURL.includes('https://webapi.nqa.gov.ae/api')) {
        const token = localStorage.getItem('access_token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      }
      return config;
    },
    (error) => Promise.reject(error)
  );


  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry &&
        originalRequest.baseURL.includes('https://webapi.nqa.gov.ae/api')
      ) {
        originalRequest._retry = true;
        try {

          const loginResponse = await axiosInstance.post('/account/login', {
            email: 'nqcwebsite@moe.gov.ae',
            password: 'NQC@2021Prod',
          });

          const {access_token} = loginResponse.data;
          localStorage.setItem('access_token', access_token);


          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
          originalRequest.headers['Authorization'] = `Bearer ${access_token}`;

          return axiosInstance(originalRequest);
        } catch (loginError) {
          return Promise.reject(loginError);
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

const axiosConfig = createAxiosInstance();
export default axiosConfig;


export {createAxiosInstance};
