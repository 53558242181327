
import React from 'react'
import MOELayout from "../../layouts/MOELayout";
import TrainingCentersList from '../../components/TrainingِAndEducational/TrainingCentersList';

const TrainingCentersPage = () => {
  return (
    <MOELayout pageClassName='service-card-page'>


      <TrainingCentersList />
    </MOELayout>
  )
}

export default TrainingCentersPage