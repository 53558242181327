
/* eslint-disable import/no-unused-modules */
import axiosConfig from "@api";

const Trainingِ = `/${isArabic ? 'ar' : 'en'}/EServices/InstitutionsSupport/_api/web/lists/getbytitle('TrainingProviders')/Items?$select=Title,EstablishmentName,Emirate,LicenseNumber,LicenseStatus,EstablishmentActivities`
const Educational = `/${isArabic ? 'ar' : 'en'}/EServices/InstitutionsSupport/_api/web/lists/getbytitle('ConsultingOffices')/Items?$select=Title,EstablishmentName,Emirate,LicenseNumber,LicenseStatus,EstablishmentActivities`

export const getServicesTrainingِ = () => axiosConfig.get(Trainingِ);
export const getServicesEducational = () => axiosConfig.get(Educational)




