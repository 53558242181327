/* eslint-disable node/no-unsupported-features/node-builtins */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import moment from "moment";
import axiosConfig from "../../api";
import { siteURL } from "../../constants/constants";

const UniversityPartnershipsDetails = () => {
    const [loading, setLoading] = useState(true);
    const [selectedPartnership, setSelectedPartnership] = useState(null);

    // get id from url  using URLSearchParams
    const searchParams = new URLSearchParams(window.location.search);

    const id =
        searchParams.get("partnershipsID")?.toLowerCase() ||
        searchParams.get("partnershipsid")?.toLowerCase() ||
        null;
    // Event list URL
    const universityPartnershipsURLByID = `/_api/web/lists/getbytitle('UniversityPartnerships')/Items(${id})`;
    // Fetch events list
    const fetchPartnershipsItem = async () => {
        try {
            const response = await axiosConfig.get(
                universityPartnershipsURLByID,
            );
            return response.data;
        } catch (err) {
            console.error(err);
            setLoading(false);
            return [];
        } finally {
            setTimeout(() => setLoading(false), 500);
        }
    };

    useEffect(async () => {
        if (id) {
            const partnershipsData = await fetchPartnershipsItem();
            setSelectedPartnership(partnershipsData);
        } else {
            setLoading(false);
            console.log("No ID found");
        }
    }, []);

    // const notAvailable = isArabic ? "غير متوفر" : "N/A";
    const notAvailable = null;

    const itemsList = [
        {
            icon: "ph ph-calendar",
            title: isArabic ? "تاريخ البدء و الانتهاء" : "Start & End Date",
            value: `${
                selectedPartnership?.PartnershipStartDate
                    ? moment(selectedPartnership?.PartnershipStartDate)
                          .locale(isArabic ? "ar" : "en")
                          .format("DD/MM/YYYY")
                    : notAvailable
            }`,
            direction: "ltr",
        },
        {
            icon: "ph ph-hand-coins",
            title: isArabic ? "اسم الجهة الشريكة" : "Partner Name",
            value:
                (isArabic
                    ? selectedPartnership?.PartnerNameAR
                    : selectedPartnership?.PartnerName) || notAvailable,
        },
        {
            icon: "ph ph-users-three",
            title: isArabic ? "نوع الشراكة" : "Partnership Type",
            value:
                (isArabic
                    ? selectedPartnership?.PartnershipTypeAr
                    : selectedPartnership?.PartnershipType) || notAvailable,
        },
        {
            icon: "ph ph-building-office",
            title: isArabic ? "جهة محلية / خارجية" : "National / International",
            value:
                (isArabic
                    ? selectedPartnership?.LocalInternationalAR
                    : selectedPartnership?.LocalInternational_x0020_) ||
                notAvailable,
        },
        {
            // url link
            icon: "ph ph-link",
            title: isArabic ? "رابط الجامعة" : "University Link",
            value: selectedPartnership?.Link || notAvailable,
            direction: "ltr",
            link: true,
        },
        // {
        //     icon: "ph ph-phone",
        //     title: isArabic ? "رقم الهاتف" : "Contact",
        //     value: selectedPartnership?.ContactNumber || notAvailable,
        //     direction: "ltr",
        // },
        // {
        //     icon: "ph ph-envelope",
        //     title: isArabic ? "البريد الإلكتروني" : "Email",
        //     value: selectedPartnership?.Email || notAvailable,
        // },
    ];

    // If loading is true, show the skeleton
    if (loading) {
        return (
            <div className="partnerships-details-wrapper my-12 flex flex-col gap-2">
                <SkeletonTheme>
                    <Skeleton className="h-full min-h-32 max-w-xs" />
                    <Skeleton className="h-full min-h-12 max-w-xs" />
                    <Skeleton className="h-full min-h-8 max-w-xs" />
                    <div className="flex flex-col">
                        {Array.from({ length: 5 }).map((_, index) => (
                            <Skeleton className="h-full min-h-4 " key={index} />
                        ))}
                    </div>
                </SkeletonTheme>
            </div>
        );
    }

    // If selectedPartnership is null, show the no partnership message
    if (selectedPartnership === null) {
        return (
            <div className="partnerships-details-wrapper my-12 flex flex-col gap-4">
                <div role="alert" className="aegov-alert alert-error">
                    <div className="alert-icon">
                        <i className="ph ph-info text-2xl" />
                    </div>
                    <div className="alert-content">
                        <div className="alert-title">
                            {isArabic ? "لا يوجد شراكة" : "No Partnership"}
                        </div>
                        <ul className="list-disc mt-3 space-y-2 ps-4">
                            <li>
                                {isArabic
                                    ? "لا يوجد شراكة حاليا للجامعة "
                                    : "No Partnership Found, Please check back later "}
                                <a
                                    href="/"
                                    className="text-blue-500 hover:text-blue-700"
                                >
                                    {isArabic
                                        ? "الرجوع إلى الصفحة الرئيسية"
                                        : "Back to Home"}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    // If selectedPartnership is not null, show the partnership details
    return (
        <div className="partnerships-details-wrapper my-12 flex flex-col gap-4">
            <img
                src={siteURL + selectedPartnership?.Logo}
                alt="Logo"
                className="w-full max-w-[340px] h-auto rounded-2xl p-4 border border-gray-200 bg-whitely-50 dark:bg-gray-900 dark:border-gray-800"
            />
            <div className="flex flex-col gap-2 mb-4 ">
                <h5 className="text-2xl font-bold  pe-6">
                    {(isArabic
                        ? `${selectedPartnership?.TitleAr} `
                        : `${selectedPartnership?.Title} `) || notAvailable}
                </h5>
                <h6 className="text-base !text-gray-500 line-clamp-1">
                    {isArabic
                        ? selectedPartnership?.UniversityNameAR
                        : selectedPartnership?.UniversityName}
                </h6>
            </div>
            <h4 className="text-xl font-bold">
                {isArabic ? "نظرة عامة" : "Overview"}
            </h4>

            <div className="flow-root flex flex-col gap-4">
                <dl className="-my-3 divide-y divide-gray-100 text-sm">
                    <div className="flex flex-col gap-1 py-3 items-start even:bg-gray-50 dark:even:bg-gray-900  sm:gap-4">
                        <dd
                            className="text-gray-700 dark:text-gray-300 text-lg col-span-2 "
                            style={{ lineHeight: "2" }}
                            dangerouslySetInnerHTML={{
                                __html: isArabic
                                    ? selectedPartnership?.PartnershipDescriptionAr
                                    : selectedPartnership?.PartnershipDescription_x0020_,
                            }}
                        />
                    </div>

                    {
                        // if item.link is true, then add a link to the item.value
                        itemsList.map(
                            (item, index) =>
                                item.value && (
                                    <div
                                        className="grid grid-cols-3 gap-1 py-3 items-center even:bg-gray-50 dark:even:bg-gray-900  sm:gap-4"
                                        key={index}
                                    >
                                        <dt className="font-medium flex items-center text-gray-900">
                                            <i
                                                className={`text-xl mx-2 ${item.icon}`}
                                            />

                                            {item.title}
                                        </dt>
                                        <dd
                                            className={`text-gray-700 dark:text-gray-300 col-span-2 ${isArabic ? "text-right" : "text-left"}`}
                                            dir={
                                                item.direction ||
                                                (isArabic ? "rtl" : "ltr")
                                            }
                                            dangerouslySetInnerHTML={{
                                                __html: item?.link
                                                    ? `<a href="${item.value}" target="_blank" rel="noopener noreferrer">${isArabic ? "الذهاب إلى موقع الجامعة" : "Go to University Website"}</a>`
                                                    : item.value,
                                            }}
                                        />
                                    </div>
                                ),
                        )
                    }
                </dl>
            </div>
        </div>
    );
};

export default UniversityPartnershipsDetails;
