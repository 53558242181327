
/* eslint-disable import/no-unused-modules */
import axiosConfig from "@api";


const apprenticeship = `/${isArabic ? 'ar' : 'en'}/NQC/_api/web/lists/getbytitle('apprenticeship')/items?$filter=Status ne null`;

const CBMC = `/${isArabic ? 'ar' : 'en'}/NQC/_api/web/lists/getbytitle('CBMC')/Items?$orderby=Title asc`;
const Policies = `/${isArabic ? 'ar' : 'en'}/NQC/_api/web/lists/getbytitle('Policies&Procedures')/Items?$select=ID,LinkFilename,FileSizeDisplay,DocType,DocDate`;

const Partners = `/${isArabic ? 'ar' : 'en'}/NQC/_api/web/lists/getbytitle('Partners')/Items?$select=Title,Description,Category,Sector,ImageURL,Order&$orderby=Order asc`
export const getServicesApprenticeship = () => axiosConfig.get(apprenticeship);

export const getServicesCBMC = () => axiosConfig.get(CBMC);

export const getServicesPolicies = () => axiosConfig.get(Policies);
export const getServicesPartners = () => axiosConfig.get(Partners)




