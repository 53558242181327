import React from "react";
import "./MOEPagenation.scss";

const PreviousButton = ({ onClick, disabled, isArabic }) => (
    <button
        onClick={onClick}
        disabled={disabled}
        className={`aegov-pagination-previous ${disabled ? "disabled" : ""}`}
        aria-label="Previous Page"
        type="button"
    >
        <i
            className={`ph ${isArabic ? "ph-caret-right" : "ph-caret-left"}   text-2xl`}
        />

        <span className="hidden lg:block">{isArabic ? "السابق" : "Prev"}</span>
    </button>
);

const NextButton = ({ onClick, disabled, isArabic }) => (
    <button
        onClick={onClick}
        disabled={disabled}
        className={`aegov-pagination-next ${disabled ? "disabled" : ""}`}
        aria-label="Next Page"
        type="button"
    >
        <span className="hidden lg:block">{isArabic ? "التالي" : "Next"}</span>
        <i
            className={`ph ${isArabic ? "ph-caret-left" : "ph-caret-right"}   text-2xl`}
        />
    </button>
);

const MOEPagenation = ({ perPage, total, handlePageClick, currentPage }) => {
    const pageNumbers = [];
    const totalPages = Math.ceil(total / perPage);

    const handlePrevious = () => {
        if (currentPage > 1) {
            handlePageClick({ target: { textContent: currentPage - 1 } });
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            handlePageClick({ target: { textContent: currentPage + 1 } });
        }
    };

    const getPageRange = () => {
        const range = [];
        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                range.push(i);
            }
        } else {
            range.push(1);
            if (currentPage > 3) range.push("...");
            const start = Math.max(currentPage - 1, 2);
            const end = Math.min(currentPage + 1, totalPages - 1);

            for (let i = start; i <= end; i++) {
                range.push(i);
            }
            if (currentPage < totalPages - 2) range.push("...");
            range.push(totalPages);
        }
        return range;
    };

    const pageRange = getPageRange();

    return (
        total > 0 && (
            <nav className="moe-pagination" aria-label="Page navigation">
                <ul className="pagination justify-center lg:justify-start rtl:lg:justify-end gap-1">
                    <li className="page-item">
                        <PreviousButton
                            onClick={handlePrevious}
                            disabled={currentPage == 1}
                            isArabic={isArabic}
                        />
                    </li>

                    {pageRange.map((number, index) => (
                        <li key={index} className="page-item ">
                            {number === "..." ? (
                                <span>...</span>
                            ) : (
                                <button
                                    type="button"
                                    onClick={(e) => handlePageClick(e)}
                                    className={`page-link !rounded-full font-bold${
                                        currentPage == number ? " active" : ""
                                    }`}
                                >
                                    {number}
                                </button>
                            )}
                        </li>
                    ))}

                    <li className="page-item">
                        <NextButton
                            onClick={handleNext}
                            disabled={currentPage == totalPages}
                            isArabic={isArabic}
                        />
                    </li>
                </ul>
            </nav>
        )
    );
};

export default MOEPagenation;
