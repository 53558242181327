/* eslint-disable node/no-missing-import */
/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
import Fade from "react-reveal/Fade";
import moment from "moment";
import React from "react";
import { siteLogo, siteURL } from "@constants/constants";
const PartnershipCard = ({ data }) => {
    const {
        Id,
        Title,
        TitleAr,
        UniversityName,
        UniversityNameAR,
        PartnershipStartDate,
        PartnershipType,
        PartnershipTypeAr,
        Logo,
        LocalInternationalAR,
        LocalInternational_x0020_,
    } = data;
    const universityLogo = Logo ? siteURL + Logo : siteLogo;

    return (
        <Fade top cascade duration={500}>
            <div
                className={`aegov-card card-bordered card-glow !shadow-primary-500/30 h-full flex flex-col items-start event-card-${Id}`}
                id={`event-${Id}`}
            >
                <div className="rounded-xl bg-gray-100 dark:bg-gray-900 w-full p-6 relative">
                    <img
                        // src={universityLogo}
                        src={universityLogo}
                        className="w-full object-contain rounded-xl h-32 mix-blend-multiply dark:mix-blend-normal"
                        alt={isArabic ? TitleAr : Title}
                    />
                    {(LocalInternationalAR || LocalInternational_x0020_) && (
                        <span className="badge bg-gray-800 text-white  inline-block overflow-ellipsis  max-w-[100%] absolute top-4 right-4 line-clamp-1">
                            {isArabic
                                ? LocalInternationalAR
                                : LocalInternational_x0020_}
                        </span>
                    )}
                </div>
                <p className="badge bg-primary mt-4 block overflow-ellipsis  max-w-[100%] line-clamp-1">
                    {isArabic ? PartnershipTypeAr : PartnershipType}
                </p>
                <div className="flex flex-col flex-1 gap-2">
                    <h5 className="text-xl font-semibold  line-clamp-2">
                        {isArabic ? TitleAr : Title}
                    </h5>
                    <h6 className="text-base !text-gray-500  line-clamp-1">
                        {isArabic ? UniversityNameAR : UniversityName}
                    </h6>
                </div>
                <div className="w-full flex gap-2 items-center justify-between">
                    <span>
                        <i className="ph ph-calendar text-lg mx-2" />
                        {PartnershipStartDate &&
                            moment(PartnershipStartDate)
                                .locale(isArabic ? "ar" : "en")
                                .format("MMM YYYY")}
                    </span>
                    <a
                        href={`${isArabic ? "/Ar" : "/En"}/AboutTheMinistry/Pages/University-Partnerships-Details.aspx?partnershipsID=${Id}`}
                        type="button"
                        className="aegov-link"
                        title={isArabic ? "عرض التفاصيل" : "View details"}
                    >
                        {isArabic ? "عرض التفاصيل" : "View details"}
                        <i className="ph ph-arrow-right link-icon rtl:-scale-x-100" />
                    </a>
                </div>
            </div>
        </Fade>
    );
};
export default PartnershipCard;
