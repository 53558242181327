import {MDBRow} from 'mdb-react-ui-kit';
import React from 'react'

import NQSFilterTabs from './NQSFilterTabs';

const NQSList = () => {


  return (
    <div className='moe-services-list services-list'>
      <MDBRow>
        {

          <NQSFilterTabs
          />
        }

      </MDBRow>
      <div className='grid lg:grid-cols-4 items-start gap-4'>

      </div>


    </div>
  )
}

export default NQSList