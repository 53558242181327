/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import Fade from "react-reveal/Fade";
import { arSA } from "date-fns/locale";

import "react-datepicker/dist/react-datepicker.css";

const FilterPartnership = ({
    filter,
    list = [],
    scopes = [],
    partnershipTypes = [],
    handelFilterChange,
    handelFilterSubmit,
    handelResetFilter,
}) => {
    const [showFilter, setShowFilter] = useState(true);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setShowFilter(false);
            } else {
                setShowFilter(true);
            }
        };
        // Initialize the state based on the current window size
        handleResize();

        // Add event listener for resize
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const renderMonthContent = (month, shortMonth, longMonth, day) => {
        const fullYear = new Date(day).getFullYear();
        const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;
        const displayMonth = isArabic ? longMonth : shortMonth;

        return <span title={tooltipText}>{displayMonth}</span>;
    };

    return (
        <div className="events-filter">
            <div className="aegov-card card-bordered !border-gray-100 dark:!border-gray-900 flex flex-col content-start justify-between md:gap-4 gap:2 ">
                <div className="flex flex-col">
                    <h3 className="text-lg font-semibold mb-0">
                        {" "}
                        {isArabic ? "تصفية " : "Filter "}{" "}
                    </h3>
                    {filter.submitted && list.length > 0 && (
                        <p className="mb-0">
                            {list.length}{" "}
                            {isArabic
                                ? "فعالية تم العثور عليها"
                                : "Items Found"}
                        </p>
                    )}
                </div>
                <Fade top cascade duration={500}>
                    {/* <form className="filters mt-0 col-span-3 flex md:flex-row flex-col gap-2" onSubmit={(e) => handelFilterSubmit(e)}> */}
                    <form
                        className="mt-0 filters flex flex-col gap-4"
                        onSubmit={(e) => handelFilterSubmit(e)}
                    >
                        {/* Search filter */}
                        <div className="aegov-form-control flex-1">
                            <label htmlFor="search">
                                {isArabic
                                    ? "ابحث عن شريك"
                                    : "Search for a Partnership"}
                            </label>
                            <div className="flex h-full gap-2">
                                <div className="form-control-input flex-1">
                                    <input
                                        type="text"
                                        id="search"
                                        name="keyword"
                                        className="w-full"
                                        value={filter.keyword}
                                        onChange={handelFilterChange}
                                    />
                                    <span className="control-suffix focus:outline-primary-400">
                                        <i className="text-2xl ph ph-magnifying-glass text-primary-600" />
                                    </span>
                                </div>

                                {/* Filter Button */}
                                <button
                                    type="button"
                                    className="aegov-btn btn-icon md:hidden"
                                    onClick={() => setShowFilter(!showFilter)}
                                >
                                    <i className="ph ph-funnel text-lg" />
                                    <span className="hidden">
                                        {isArabic ? "تصفية" : "Filter"}
                                    </span>
                                </button>
                            </div>
                        </div>

                        {/* University Name filter [select] */}
                        <div
                            className={`aegov-form-control ${showFilter ? "" : "hidden"}`}
                        >
                            <label htmlFor="internationalLocal">
                                {isArabic
                                    ? "محلي/عالمي"
                                    : "National/International"}
                            </label>
                            <div className="form-control-input">
                                <select
                                    name="scope"
                                    id="internationalLocal"
                                    value={filter.scope || ""}
                                    onChange={handelFilterChange}
                                    disabled={scopes.length === 0}
                                >
                                    <option value="">
                                        {isArabic ? "الكل" : "All"}
                                    </option>
                                    {scopes.length > 0 &&
                                        scopes.map((uni, index) => (
                                            <option key={index} value={uni}>
                                                {uni}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <div
                            className={`aegov-form-control ${showFilter ? "" : "hidden"}`}
                        >
                            <label htmlFor="universityName">
                                {isArabic ? "نوع الشراكة" : "Partnership Type"}
                            </label>
                            <div className="form-control-input">
                                <select
                                    name="partnershipType"
                                    id="PartnershipType"
                                    value={filter.partnershipType || ""}
                                    onChange={handelFilterChange}
                                    disabled={partnershipTypes.length === 0}
                                >
                                    <option value="">
                                        {isArabic ? "الكل" : "All"}
                                    </option>
                                    {partnershipTypes.length > 0 &&
                                        partnershipTypes.map((uni, index) => (
                                            <option key={index} value={uni}>
                                                {uni}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        {/* Start Date filter [date picker] */}
                        <div
                            className={`aegov-form-control ${showFilter ? "" : "hidden"}`}
                        >
                            <label htmlFor="startDate">
                                {isArabic
                                    ? "تاريخ الشراكة"
                                    : "Partnership Date"}
                            </label>
                            <div className="form-control-input flex-1 overflow-visible">
                                <DatePicker
                                    locale={isArabic ? arSA : "en"}
                                    selected={filter.startDate || null}
                                    renderMonthContent={renderMonthContent}
                                    showMonthYearPicker
                                    showFullMonthYearPicker // show full month and year picker January 2025 instead of Jan 2025
                                    dateFormat="MMM yyyy"
                                    placeholderText={
                                        isArabic
                                            ? "اختر تاريخ الشراكة"
                                            : "Select Partnership Date"
                                    }
                                    onChange={(date) =>
                                        handelFilterChange({
                                            target: {
                                                name: "startDate",
                                                value: date,
                                            },
                                        })
                                    }
                                />
                            </div>
                        </div>

                        {/* Apply and Reset buttons */}
                        <div className="flex gap-2 self-end max-md:mt-4">
                            <button
                                className="aegov-btn hover:!text-primary-50"
                                type="submit"
                            >
                                <i className="ph ph-magnifying-glass text-lg" />
                                {isArabic ? "بحث" : "Search"}
                            </button>
                            {filter.submitted && (
                                <button
                                    type="button"
                                    className="aegov-btn btn-outline !text-aered-500 border-aered-600 hover:!border-aered-300 hover:!bg-aered-50 md:btn-icon"
                                    onClick={() => handelResetFilter()}
                                >
                                    <i className="ph ph-x text-lg" />
                                    <span className="md:hidden">
                                        {isArabic ? "إعادة تعيين" : "Reset"}
                                    </span>
                                </button>
                            )}
                        </div>
                    </form>
                </Fade>
            </div>
        </div>
    );
};

export default FilterPartnership;
