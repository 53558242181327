/* eslint-disable node/no-missing-import */
/* eslint-disable react/jsx-filename-extension */
import React, {lazy, Suspense} from "react";
import ReactDOM from "react-dom/client";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";

import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {Provider} from "react-redux";
import store from "./redux/store";
import {AppLoader} from "./helper";
import App from "./App";

// Make React Global Variable for use in all components
window.React = React;
window.ReactDOM = ReactDOM;
window.Skeleton = Skeleton;
window.SkeletonTheme = SkeletonTheme;
window.isArabic = isArabic;

// Start loader
AppLoader();

// Lazy load the components
const MOEHeader = lazy(() => import("@components/shared/MOEHeader"));
const MOEFooter = lazy(() => import("@components/shared/MOEFooter"));

// Helper function to render a React component to a specific element
const renderReactComponent = (elementId, Component, skeletonClassName) => {
    const element = document.getElementById(elementId);
    if (element) {
        const root = ReactDOM.createRoot(element);
        root.render(
            <Provider store={store}>
                <Suspense fallback={<Skeleton className={skeletonClassName} />}>
                    <Component />
                </Suspense>
            </Provider>,
        );
    }
};

// Render the header, content, and footer elements if they exist
renderReactComponent("moe-header", MOEHeader, "header-skeleton");
renderReactComponent("moe-content", App, "content-skeleton");
renderReactComponent("moe-footer", MOEFooter, "footer-skeleton");
