import React from "react";
import { useSelector } from "react-redux";
import LegislationFilter from "../../components/DLS/DLSLegislation/LegislationFilter";
import DLSLegislation from "../../components/DLS/DLSLegislation";
import LegislationLogo from "../../components/DLS/DLSLegislation/LegislationLogo";

const Law = () => {
    const { FederalLaws, FederalDecrees } = useSelector(
        (state) => state.legislation,
    );
    return (
        <div className="flex flex-col gap-10">
            <LegislationFilter />
            <DLSLegislation
                data={FederalLaws}
                title={isArabic ? "​القوانين الاتحادية" : "​​​Federal Laws​"}
            />
            <DLSLegislation
                data={FederalDecrees}
                title={isArabic ? "المراسيم بقانون" : "​F​ederal Decree Law​"}
            />
            {/* Logo Container */}
            <LegislationLogo />
        </div>
    );
};

export default Law;
