/* eslint-disable import/no-unused-modules */
export const siteURL = isLive ? "" : process.env.REACT_APP_BASE_URL;
export const USP_API = process.env.REACT_APP_USP_API

export const siteTitle = "Ministry of higher education and scientific research";
export const siteTitleAr = "وزارة التعليم العالي والبحث العلمي";

export const siteLogo = `${siteURL}/SiteAssets/face%20lift/assets/images/mohesr-logo${isArabic ? '-ar' : ''}.svg`;
export const siteLogoLight = `${siteURL}/SiteAssets/face%20lift/assets/images/mohesr-logo-light${isArabic ? '-ar' : ''}.png`;

export const QuadrabayLink = "https://moe.quadrabay.com";
export const DFGatewayLink = "https://moe.dfgateway.com/";
export const NQSGatewayLink = "https://webapi.nqa.gov.ae/";



export const ItemsPerPage = 12;

export const applicationLinks = [
    {
        "id": "android-app",
        "icon": "fab fa-google-play",
        "svg": "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='none' d='M0 0h24v24H0z'></path><path d='M3.60972 1.81396L13.793 12L3.61082 22.1864C3.41776 22.1048 3.24866 21.962 3.13555 21.7667C3.0474 21.6144 3.00098 21.4416 3.00098 21.2656V2.73453C3.00098 2.32109 3.25188 1.96625 3.60972 1.81396ZM14.5 12.707L16.802 15.009L5.86498 21.342L14.5 12.707ZM17.699 9.50896L20.5061 11.1347C20.9841 11.4114 21.1473 12.0232 20.8705 12.5011C20.783 12.6523 20.6574 12.778 20.5061 12.8655L17.698 14.491L15.207 12L17.699 9.50896ZM5.86498 2.65796L16.803 8.98996L14.5 11.293L5.86498 2.65796Z'></path></svg>",
        "title": "Google Play",
        "subtitle": "Android App",
        "titleAr": "متجر جوجل",
        "subtitleAr": "تطبيق الأندرويد",
        "link": "https://play.google.com/store/apps/details?id=ae.gov.moe.mobileservices"
    },
    {
        "id": "ios-app",
        "icon": "fab fa-apple",
        "svg": "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='none' d='M0 0h24v24H0z'></path><path d='M11.6734 7.2221C10.7974 7.2221 9.44138 6.2261 8.01338 6.2621C6.12938 6.2861 4.40138 7.3541 3.42938 9.0461C1.47338 12.4421 2.92538 17.4581 4.83338 20.2181C5.76938 21.5621 6.87338 23.0741 8.33738 23.0261C9.74138 22.9661 10.2694 22.1141 11.9734 22.1141C13.6654 22.1141 14.1454 23.0261 15.6334 22.9901C17.1454 22.9661 18.1054 21.6221 19.0294 20.2661C20.0974 18.7061 20.5414 17.1941 20.5654 17.1101C20.5294 17.0981 17.6254 15.9821 17.5894 12.6221C17.5654 9.8141 19.8814 8.4701 19.9894 8.4101C18.6694 6.4781 16.6414 6.2621 15.9334 6.2141C14.0854 6.0701 12.5374 7.2221 11.6734 7.2221ZM14.7934 4.3901C15.5734 3.4541 16.0894 2.1461 15.9454 0.850098C14.8294 0.898098 13.4854 1.5941 12.6814 2.5301C11.9614 3.3581 11.3374 4.6901 11.5054 5.9621C12.7414 6.0581 14.0134 5.3261 14.7934 4.3901Z'></path></svg>",
        "title": "App Store",
        "subtitle": "Download on the",
        "titleAr": "متجر التطبيقات",
        "subtitleAr": "تطبيق الأيفون",
        "link": "https://apps.apple.com/jo/app/moe-uae/id1475400660"
    }
]

export const contactData = {
    phone: {
        "title": "Toll Free Number (24/7):",
        "titleAr": "الرقم المجاني (24/7)",
        "value": "+971 800 511"
    },
    email: {
        "title": "Email",
        "titleAr": "البريد الإلكتروني",
        "value": "Info@mohesr.gov.ae"
    },
    liveChat: {
        "title": "Live Chat",
        "title_ar": "الدردشة المباشرة",
        "label": "Talk to a Live Agent",
        "label_ar": "تحدث الى موظف",
        "value": ""
    },

    contactMinister: {
        "label": "Contact The Minister",
        "label_ar": "اتصل بالوزير",
        "button": {
            "link": "eParticipation/Pages/Contact-The-Ministers.aspx",
            "class": "btn btn-link",
            "label": "Get in touch",
            "label_ar": "تواصل معنا"
        }
    },
}

export const workHours = [
    {
        "days": "Monday - Thursday",
        "daysAr": "الإثنين - الخميس",
        "time": "8:00 am - 2:30 pm",
        "timeAr": "8:00 ص - 2:30 م"
    },
    {
        "days": "Friday",
        "daysAr": "الجمعة",
        "time": "09:00 am - 12:00 pm",
        "timeAr": "09:00 ص - 12:00 م"
    }
]
