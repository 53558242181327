/* eslint-disable node/no-missing-import */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { MDBSpinner } from "mdb-react-ui-kit";
import { Bounce, toast } from "react-toastify";
import { ItemsPerPage } from "@constants/constants";
import DLSPagination from "@components/DLS/DLSPagination";
import moment from "moment";
import axiosConfig from "../../api";
import FilterPartnership from "./components/FilterPartnership";
import PartnershipCard from "./components/PartnershipCard";
import PartnershipsDetailsModal from "./components/PartnershipsDetailsModal";
import "./styles.scss";

const UniversityPartnershipsPage = () => {
    const [loading, setLoading] = useState(true);
    const [partnershipsList, setPartnershipsList] = useState([]);
    const [filteredPartnershipsList, setFilteredPartnershipsList] = useState(
        [],
    );
    const [selectedPartnership, setSelectedPartnership] = useState(null);
    // For Filters
    const [scopeList, setScopeList] = useState([]);
    const [partnershipTypeList, setPartnershipTypeList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [eventsPerPage] = useState(ItemsPerPage);
    // Filter state
    const initialFilter = {
        keyword: "",
        Title: "",
        startDate: "",
        scope: "",
        partnershipType: "",
        submitted: false,
    };
    const [filter, setFilter] = useState(initialFilter);
    // Event list URL
    const universityPartnershipsURL = `/_api/web/lists/getbytitle('UniversityPartnerships')/Items`;

    // Fetch events list
    const fetchPartnershipsList = async () => {
        try {
            const response = await axiosConfig.get(universityPartnershipsURL);
            // Sort events by start date
            const sortedPartnerships = response.data?.value;
            setPartnershipsList(sortedPartnerships);
            setFilteredPartnershipsList(sortedPartnerships);

            const scopes = [
                ...new Set(
                    sortedPartnerships
                        .filter(
                            (item) =>
                                item[
                                    isArabic
                                        ? "LocalInternationalAR"
                                        : "LocalInternational_x0020_"
                                ],
                        )
                        .map(
                            (item) =>
                                item[
                                    isArabic
                                        ? "LocalInternationalAR"
                                        : "LocalInternational_x0020_"
                                ],
                        ),
                ),
            ];
            setScopeList(scopes);
            const partnershipTypes = [
                ...new Set(
                    sortedPartnerships.map((item) => item.PartnershipType),
                ),
            ];
            setPartnershipTypeList(partnershipTypes);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    // Handle pagination
    const handelPagination = (pageNumber) => {
        // 1- Start loading
        setLoading(true);
        // 2- Scroll to top
        window.scrollTo(0, 0);
        setTimeout(() => {
            // 3- Set current page
            setCurrentPage(pageNumber);
            // 4- Stop loading after 1 second
            setLoading(false);
        }, 1000);
    };

    // Handle opening event details modal
    const handelOpenPartnershipDetails = (item) => {
        setSelectedPartnership(item);
    };

    // Handle filter change
    // Handle Filter Change
    const handelFilterChange = (e) => {
        const { name, value } = e.target;
        console.log("name", name);
        console.log("value", value);
        setFilter({ ...filter, [name]: value });
    };

    const handelResetFilter = () => {
        setFilter(initialFilter);
        setFilteredPartnershipsList(partnershipsList);
    };

    // Handle Filter Submit
    const handelFilterSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let filteredPartnerships = partnershipsList;
        // The Date that the user selected

        if (
            filter.keyword === "" &&
            filter.startDate === "" &&
            filter.endDate === "" &&
            filter.sector === "" &&
            filter.scope === "" &&
            filter.partnershipType === ""
        ) {
            const message = isArabic
                ? "الرجاء إدخال كلمة مفتاحية أو تحديد تاريخ"
                : "Please enter a keyword or select a date";
            toast.error(message, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                icon: "ph ph-warning",
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
            setFilteredPartnershipsList(partnershipsList);
            setLoading(false);
            return;
        }

        if (filter.keyword) {
            // remove spaces from start and end of the keyword
            const filteredKeyword = filter.keyword.trim();

            filteredPartnerships = filteredPartnerships.filter(
                (item) =>
                    // Srarch with [TitleAR | Title] and [PartnerNameAr | PartnerName] and [UniversityNameAR | UniversityName]
                    item[isArabic ? "TitleAR" : "Title"]
                        .toLowerCase()
                        .includes(filteredKeyword.toLowerCase()) ||
                    item[isArabic ? "PartnerNameAr" : "PartnerName"]
                        .toLowerCase()
                        .includes(filteredKeyword.toLowerCase()) ||
                    item[isArabic ? "UniversityNameAR" : "UniversityName"]
                        .toLowerCase()
                        .includes(filteredKeyword.toLowerCase()),
            );
        }

        if (filter.scope) {
            filteredPartnerships = filteredPartnerships.filter(
                (item) =>
                    item[
                        isArabic
                            ? "LocalInternationalAR"
                            : "LocalInternational_x0020_"
                    ] === filter.scope,
            );
        }

        if (filter.partnershipType) {
            filteredPartnerships = filteredPartnerships.filter(
                (item) =>
                    item[isArabic ? "PartnershipTypeAr" : "PartnershipType"] ===
                    filter.partnershipType,
            );
        }
        if (filter.startDate) {
            filteredPartnerships = filteredPartnerships.filter((item) => {
                console.log(
                    "item",
                    moment(item.PartnershipStartDate).format("MMM YYYY"),
                );
                console.log(
                    "filter",
                    moment(filter.startDate).format("MMM YYYY"),
                );
                return (
                    moment(item.PartnershipStartDate).format("MMM YYYY") ===
                    moment(filter.startDate).format("MMM YYYY")
                );
            });
        }
        setTimeout(() => {
            setFilteredPartnershipsList(filteredPartnerships); // set filtered events list
            setFilter({ ...filter, submitted: true }); // set submitted to true, to show the [filter badge] and [clear button]
            setCurrentPage(1); // reset pagination
            setLoading(false);
        }, 1000);
    };

    useEffect(() => {
        fetchPartnershipsList();
    }, []);

    // This useEffect runs whenever selectedEvent changes
    useEffect(() => {
        if (selectedPartnership) {
            $("#partnershipDetails").modal("show");
        }
        // Close the modal, reset selected event to null
        $("#partnershipDetails").on("hidden.bs.modal", () => {
            setSelectedPartnership(null);
        });
    }, [selectedPartnership]);

    return (
        <div className="relative prose max-w-none">
            {/*  Event details modal */}
            {selectedPartnership && (
                <PartnershipsDetailsModal data={selectedPartnership} />
            )}

            {/* Loading spinner */}
            <div
                className="events-loading-wrap flex justify-center items-center absolute top-0 left-0 w-full h-full bg-whitely-50/75 dark:bg-darkly-50/75 z-50"
                style={{ display: loading ? "flex" : "none" }}
            >
                <MDBSpinner color="primary">
                    <span className="visually-hidden">Loading...</span>
                </MDBSpinner>
            </div>
            {/* Main content [Partnerships list and Filters] */}

            <div className="events-wrapper md:grid grid-cols-4 content-start flex flex-col-reverse gap-4">
                {/* Partnerships list */}
                {filteredPartnershipsList.length > 0 ? (
                    <div className="grid xl:grid-cols-3 sm:grid-cols-2 col-span-3 content-start gap-4 mb-8">
                        {
                            // Loop over the partnerships list and display each partnership
                            filteredPartnershipsList
                                .slice(
                                    (currentPage - 1) * eventsPerPage,
                                    currentPage * eventsPerPage,
                                )
                                .map((item, index) => (
                                    <div key={index} className="h-full">
                                        <PartnershipCard
                                            type="university"
                                            data={item}
                                            onClick={() =>
                                                handelOpenPartnershipDetails(
                                                    item,
                                                )
                                            }
                                        />
                                    </div>
                                ))
                        }
                    </div>
                ) : (
                    // Not Found Events
                    <div className="col-span-3">
                        {loading || (
                            <div className="card  bg-whitely-50 dark:bg-gray-900 p-4">
                                <div
                                    role="alert"
                                    className="aegov-alert alert-error"
                                >
                                    <div className="alert-icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 256 256"
                                            aria-hidden="true"
                                        >
                                            <rect
                                                width="256"
                                                height="256"
                                                fill="none"
                                            />
                                            <path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM120,104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm8,88a12,12,0,1,1,12-12A12,12,0,0,1,128,192Z" />
                                        </svg>
                                    </div>
                                    <div className="alert-content">
                                        <div className="alert-title mb-2">
                                            {" "}
                                            {isArabic
                                                ? "لا توجد جامعات/جهات مشاركة"
                                                : "No Universities/Partnerships Found"}{" "}
                                        </div>
                                        <p>
                                            {" "}
                                            {isArabic
                                                ? "لا يوجد جامعات/جهات مشاركة"
                                                : "There are no Universities/Partnerships"}{" "}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {/*  Filters Bar */}
                <div className="col-span-1">
                    <FilterPartnership
                        filter={filter}
                        list={filteredPartnershipsList}
                        scopes={scopeList}
                        partnershipTypes={partnershipTypeList}
                        handelResetFilter={handelResetFilter}
                        handelFilterChange={handelFilterChange}
                        handelFilterSubmit={handelFilterSubmit}
                    />
                </div>
            </div>

            {filteredPartnershipsList.length > 0 && (
                <DLSPagination
                    currentPage={currentPage}
                    totalItems={filteredPartnershipsList.length}
                    itemsPerPage={eventsPerPage}
                    onPageChange={handelPagination}
                />
            )}
        </div>
    );
};

export default UniversityPartnershipsPage;
